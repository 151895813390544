// import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
// import React from 'react'

// const OfferSection = () => {
//     return (
//         <div>

//             <Box>
//                 <Typography
//                     variant="h4"
//                     align="center"
//                     gutterBottom
//                     sx={{ fontWeight: "bold" }}
//                 >
//                     What We’re Offering
//                 </Typography>
//                 <Grid container spacing={3}>
//                     {[1, 2, 3, 4].map((offer) => (
//                         <Grid item xs={12} sm={6} md={3} key={offer}>
//                             <Card
//                                 sx={{
//                                     textAlign: "center",
//                                     boxShadow: 2,
//                                     "&:hover": { boxShadow: 4 },
//                                 }}
//                             >
//                                 <CardContent>
//                                     <Box
//                                         sx={{
//                                             width: 50,
//                                             height: 50,
//                                             mx: "auto",
//                                             backgroundColor: "primary.main",
//                                             color: "white",
//                                             borderRadius: "50%",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             justifyContent: "center",
//                                             mb: 2,
//                                         }}
//                                     >
//                                         <Typography variant="h6">{offer}</Typography>
//                                     </Box>
//                                     <Typography variant="h6">Lorem Ipsum</Typography>
//                                     <Typography variant="body2">
//                                         Lorem ipsum is are many variations of passages of majority.
//                                     </Typography>
//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Box>
//         </div>
//     )
// }

// export default OfferSection


import React from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { GrSystem } from "react-icons/gr";
import upload_img from "../../Assets/upload_img.svg"


const OfferSection = () => {

  const steps = [
    {
      id: "1",
      icon: upload_img,

      heading: "Heading 1",
      text: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      id: "2",
      icon: upload_img,


      heading: "Heading 2",
      text: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      id: "3",
      icon: upload_img,


      heading: "Heading 3",
      text: "There are many variations of passages There are many variations of passages of Lorem Ipsum majority have suffered. "
    },
    {
      id: "4",
      icon: upload_img,

      heading: "Heading 4",
      text: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      id: "4",
      icon: upload_img,

      heading: "Heading 4",
      text: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
    {
      id: "4",
      icon: upload_img,

      heading: "Heading 4",
      text: "There are many variations of passages of Lorem Ipsum majority have suffered There are many variations of passages of Lorem Ipsum majority have suffered.."
    },
  ];
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: "30px" }}>
        What We’re<span style={{ fontWeight: "800" }}> Offering</span>
      </Typography>
      <Typography
        sx={{
          paddingBottom: "5px",
          maxWidth: "900px",
          margin: "0 auto",
          textAlign: "center",
          color: "#b0aaaa",
          fontSize: "14px",

        
        }}
      >
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry’s standard dummy text ever since the
        1500s, when an unknown printer took a galley of type and scrambled it to
        make a type specimen book.
      </Typography>

      <Grid container spacing={4} sx={{ p: 6 }}>
        {steps.map((steps) => (
          <Grid item xs={12} sm={6} md={4} key={steps.id}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Card sx={{
              boxShadow: "none", height: "200px", width: "330px",
              backgroundColor: "#F7F7F7"


            }}>

              <CardContent>
                <Box
                  sx={{
                    // width: 50,
                    // height: 50,
                    // color: "white",
                    // borderRadius: "50%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mb: 2,
                    fontSize: "50px",
                    // color: "#FFAA17"

                  }}
                >
                  <Box sx={{ border: '1px solid #C38F51', background: '#C38F51', borderRadius: "50%", display: "flex", alignItems: "center", mr: 1 }}>
                    <img src={steps.icon} style={{ padding: "14px" }}></img>
                  </Box>
                  <Typography variant="body1" color="#C38F51" fontSize="17px">{steps.heading}</Typography>

                </Box>
                <Typography variant="body2" color="textSecondary" sx={{ minWidth: "100px" }}>
                  {steps.text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OfferSection;
