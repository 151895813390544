import React from "react";
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Card,
    CardMedia,
    CardContent,
    Container,
} from "@mui/material";
import gold_image from "../../../Assets/gold-bars-background-free-photo.jpg"
import { Link } from "react-router-dom";
import { IoMdPricetag } from "react-icons/io";
import GoldInfoAccordion from "./GoldInfoAccordion";



const GoldInvestment = () => {
    return (
        <Grid container p={6}>
            {/* Gold Bar Section */}

            <Grid
                xs={6}
                sx={{
                    position: "relative",
                    background: `linear-gradient(90deg, #000000 0%, #2E2E2ED6 51%, #1D1D1D 100%) , url(${gold_image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column", // Ensures children are stacked vertically
                    justifyContent: "flex-end", // Pushes children to the bottom
                    alignItems: "center", // Centers horizontally
                    overflow: "hidden",
                    // textAlign:"center"
                }}
            >
                <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        // flexWrap="wrap"
                        p={10}
                        color="white"
                    >
                        <Box sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    marginTop: 2,
                                    fontSize: "16px",
                                }}
                            >
                                Estimated Reap Benefit
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#1FC45B",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                }}
                            >
                                6%* <span style={{ color: "white", fontSize: "13px" }}>Per Quarter</span>
                            </Typography>
                            <Link style={{ fontSize: "10px", color: "white" }}>
                                * Read Offer Documents
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Grid>


            {/* Details Section */}
            <Grid xs={6}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}
                    >
                        <IoMdPricetag style={{ marginRight: 8 }} /> Initial Stake
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography>13.8 Grm Staked</Typography>
                            <Typography>$1000 worth Gold Purchased</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="right">on 15-Jan-2025</Typography>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            marginTop: 2,
                            padding: 2,
                            backgroundColor: "#ffe5b4",
                            borderRadius: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            6% Per Quarter
                        </Typography>
                    </Box>
                    {[
                        { duration: "+ 6 Months", grams: "0.831759" },
                        { duration: "+ 12 Months", grams: "0.831759" },
                        { duration: "+ 18 Months", grams: "0.831759" },
                        { duration: "+ 24 Months", grams: "0.831759" },
                    ].map((item, index) => (
                        <Grid
                            container
                            spacing={2}
                            key={index}
                            sx={{
                                padding: 1,
                                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                                borderRadius: 1,
                                marginY: 1,
                            }}
                        >
                            <Grid item xs={8}>
                                {/* <Typography>Purchase Date {item.duration}</Typography> */}
                                <Box component="ul" sx={{ paddingLeft: 2, listStyleType: 'disc' }}>

                                    <Typography
                                        // variant="h6"
                                        sx={{ fontSize: "16px", fontWeight: "600", marginBottom: 2, display: "flex", alignItems: "center" }}
                                    >
                                        <IoMdPricetag
                                            style={{ marginRight: 8 }}
                                        /> Purchase Date {item.duration}
                                    </Typography>

                                    {/* <Box component="ul" sx={{ paddingLeft: 2, listStyleType: 'disc' }}> */}

                                    <Typography component="li" sx={{ marginBottom: 1 }}>
                                        {item.grams} Grm Staked
                                    </Typography>
                                    <Typography component="li" sx={{ marginBottom: 1 }}>
                                        $1000 worth Gold Purchased
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography align="right">on 15-Jan-2025</Typography>
                            </Grid>
                        </Grid>
                    ))}
                    <Typography variant="body2" sx={{ marginTop: 2 }}>
                        Total Of <b>13.8 GRAMS + 3.32704 Grams</b> i.e.{" "}
                        <b>17.127 Grams</b> Worth Of Gold Will Be Staked On The Maturity Date.
                    </Typography>
                </Paper>

                {/* Buttons */}
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "black",
                            "&:hover": { backgroundColor: "#e0e0e0" },
                        }}
                    >
                        Add to Cart
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        startIcon={<span>⚡</span>}
                    >
                        Buy Now
                    </Button>
                </Box>
            </Grid>
            <GoldInfoAccordion />
        </Grid>
    );
};

export default GoldInvestment;
