

// // // import React from "react";
// // // import { Box, Button, Typography, Container, Stack } from "@mui/material";
// // // import Logo_gold from "../../Assets/Logo_gold.png"
// // // import home_side_image from "../../Assets/Overlay.png"


// // // const Home = () => {
// // //     return (
// // //         <Box
// // //             sx={{
// // //                 // backgroundColor: "#004d40",
// // //                 backgroundColor: "#00675B",
// // //                 background:`url(${home_side_image})`,

// // //                 // Dark green background
// // //                 minHeight: "100vh",
// // //                 display: "flex",
// // //                 alignItems: "center",
// // //                 justifyContent: "center",
// // //             }}
// // //         >
// // //             {/* <img src={home_side_image}></img> */}
// // //             <Container maxWidth="lg">
// // //                 <Box
// // //                     display="flex"
// // //                     alignItems="center"
// // //                     justifyContent="space-around"
// // //                     flexWrap="wrap"
// // //                 >
// // //                     {/* Left Section */}
// // //                     <Box
// // //                         sx={{
// // //                             maxWidth: "500px",
// // //                             color: "#fff",
// // //                         }}
// // //                     >
// // //                         <Typography
// // //                             variant="h2"
// // //                             textAlign="start"
// // //                             sx={{
// // //                                 fontWeight: "bold",
// // //                                 mb: 2,
// // //                             }}
// // //                         >
// // //                             BEST GOLD STOCKING COMPANY
// // //                         </Typography>
// // //                         <Typography
// // //                             variant="body2"
// // //                             textAlign="start"

// // //                             sx={{
// // //                                 mb: 3,
// // //                                 lineHeight: "1.6",
// // //                             }}
// // //                         >
// // //                             We are committed to providing our customers with exceptional
// // //                             service while offering our employees the best training.
// // //                         </Typography>
// // //                         <Stack spacing={2} direction="row">
// // //                             <Button
// // //                                 variant="contained"
// // //                                 sx={{
// // //                                     backgroundColor: "black",
// // //                                     color: "white",
// // //                                     fontWeight: "12px",
// // //                                     padding: "10px 20px",
// // //                                     "&:hover": {
// // //                                         backgroundColor: "#d68a00",
// // //                                     },
// // //                                 }}
// // //                             >
// // //                                 Already an number
// // //                             </Button>
// // //                             <Button
// // //                                 variant="contained"
// // //                                 sx={{
// // //                                     backgroundColor: "#f9a825",
// // //                                     color: "#fff",
// // //                                     // fontWeight: "bold",
// // //                                     padding: "10px 20px",
// // //                                     "&:hover": {
// // //                                         backgroundColor: "#d68a00",
// // //                                     },
// // //                                 }}
// // //                             >
// // //                                 Get Started Now
// // //                             </Button>
// // //                         </Stack>
// // //                     </Box>

// // //                     {/* Right Section */}
// // //                     <Box
// // //                         sx={{
// // //                             // position: "relative",
// // //                             // //   width: "300px",
// // //                             // //   height: "300px",
// // //                             borderRadius: "50%",
// // //                             // overflow: "hidden",
// // //                             display: "flex",
// // //                             justifyContent: "center",
// // //                             alignItems: "center",
// // //                             background: "#004d40",
// // //                             padding: "10px",
// // //                             // backgroundColor: "#fff",
// // //                             // border:"3px solid red"
// // //                         }}
// // //                     >
// // //                         <img
// // //                             src={Logo_gold}// Replace with actual image URL
// // //                             alt="Gold Stake Logo"
// // //                             style={{
// // //                                 width: "450px",
// // //                                 height: "450px",
// // //                                 // width: "20%",
// // //                                 // height: "20%",
// // //                                 objectFit: "cover",
// // //                                 // padding:"10px"
// // //                             }}
// // //                         />
// // //                     </Box>
// // //                 </Box>
// // //             </Container>
// // //         </Box>
// // //     );
// // // };

// // // export default Home;


// // import React from "react";
// // import { Box, Button, Typography, Container, Stack } from "@mui/material";
// // import Logo_gold from "../../Assets/Logo_gold.png";
// // import home_side_image from "../../Assets/Overlay.png"; // Replace this with your actual image path

// // const Home = () => {
// //     return (
// //         <Box
// //             sx={{
// //                 position: "relative", // Important for layering
// //                 backgroundColor: "#00675B",
// //                 minHeight: "100vh",
// //                 display: "flex",
// //                 alignItems: "center",
// //                 justifyContent: "center",
// //                 overflow: "hidden", // Ensures no unwanted scroll
// //             }}
// //         >
// //             {/* Bottom-Left Background Image */}
// //             <Box
// //                 component="img"
// //                 src={home_side_image} // Correct file path to your image
// //                 alt="Background Overlay"
// //                 sx={{
// //                     // position: "absolute",
// //                     // // top: 0,
// //                     // bottom:0,
// //                     // left: 0,
// //                     // width: "40%", // Adjust the size as needed

// //                     // zIndex: 1, // Keeps it behind the content

// //                     position: "absolute",
// //                     bottom: "-20%", // Adjust based on the design
// //                     left: "-20%",  // Moves it outside the container for half visibility
// //                     width: "48%",  // Adjust for size
// //                     height: "86%",

// //                     borderRadius: "50%", // Makes it a circle
// //                     background: `url(${home_side_image}) no-repeat center`,
// //                     backgroundSize: "cover",
// //                     zIndex: 1,
// //                 }}
// //             />

// //             <Container maxWidth="lg" sx={{ zIndex: 2 }}>
// //                 <Box
// //                     display="flex"
// //                     alignItems="center"
// //                     justifyContent="space-around"
// //                     flexWrap="wrap"
// //                 >
// //                     {/* Left Section */}
// //                     <Box
// //                         sx={{
// //                             maxWidth: "500px",
// //                             color: "#fff",
// //                         }}
// //                     >
// //                         <Typography
// //                             variant="h2"
// //                             textAlign="start"
// //                             sx={{
// //                                 fontWeight: "bold",
// //                                 mb: 2,
// //                             }}
// //                         >
// //                             BEST GOLD STOCKING COMPANY
// //                         </Typography>
// //                         <Typography
// //                             variant="body2"
// //                             textAlign="start"
// //                             sx={{
// //                                 mb: 3,
// //                                 lineHeight: "1.6",
// //                             }}
// //                         >
// //                             We are committed to providing our customers with exceptional
// //                             service while offering our employees the best training.
// //                         </Typography>
// //                         <Stack spacing={2} direction="row">
// //                             <Button
// //                                 variant="contained"
// //                                 sx={{
// //                                     backgroundColor: "black",
// //                                     color: "white",
// //                                     fontWeight: "12px",
// //                                     padding: "10px 20px",
// //                                     "&:hover": {
// //                                         backgroundColor: "#d68a00",
// //                                     },
// //                                 }}
// //                             >
// //                                 Already a number
// //                             </Button>
// //                             <Button
// //                                 variant="contained"
// //                                 sx={{
// //                                     backgroundColor: "#f9a825",
// //                                     color: "#fff",
// //                                     padding: "10px 20px",
// //                                     "&:hover": {
// //                                         backgroundColor: "#d68a00",
// //                                     },
// //                                 }}
// //                             >
// //                                 Get Started Now
// //                             </Button>
// //                         </Stack>
// //                     </Box>

// //                     {/* Right Section */}
// //                     <Box
// //                         sx={{
// //                             borderRadius: "50%",
// //                             display: "flex",
// //                             justifyContent: "center",
// //                             alignItems: "center",
// //                             background: "#004d40",
// //                             padding: "10px",
// //                         }}
// //                     >
// //                         <img
// //                             src={Logo_gold}
// //                             alt="Gold Stake Logo"
// //                             style={{
// //                                 width: "450px",
// //                                 height: "450px",
// //                                 objectFit: "cover",
// //                             }}
// //                         />
// //                     </Box>
// //                 </Box>
// //             </Container>
// //         </Box>
// //     );
// // };

// // export default Home;


// import React from "react";
// import { Box, Button, Typography, Container, Stack } from "@mui/material";
// import home_page_Image from "../../Assets/home_page_Image.png";
// import home_side_image from "../../Assets/Overlay.png"; // Ensure this is the correct path

// const LandingPage = () => {
//     return (
//         <Box
//             sx={{
//                 // top:10,
//                 // padding:"10px",
//                 position: "relative", // Needed for positioning child elements
//                 background:`url(${home_page_Image})`,
//                 // minHeight: "90vh",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 overflow: "hidden",
//                 padding: "50px 0px"

//             }}
//         >
//             {/* Bottom-Left Half Circle */}
//             <Box
//                 // sx={{
//                 //     position: "absolute",
//                 //     bottom: "0", // Adjust based on the design
//                 //     left: "0",  // Moves it outside the container for half visibility
//                 //     width: "20%",  // Adjust for size
//                 //     height: "70%",
//                 //     // borderRadius: "50%", // Makes it a circle
//                 //     background: `url(${home_side_image}) `,
//                 //     backgroundSize: "cover",
//                 //     zIndex: 1,
//                 // }}
//             />

//             <Container maxWidth="lg" sx={{ zIndex: 2 }}>
//                 <Box
//                     display="flex"
//                     alignItems="center"
//                     justifyContent="space-around"
//                     flexWrap="wrap"
//                 >
//                     {/* Left Section */}
//                     <Box
//                         sx={{
//                             maxWidth: "500px",
//                             color: "#fff",
//                         }}
//                     >
//                         <Typography
//                             variant="h1"

//                             textAlign="start"
//                             sx={{
//                                 fontWeight: "bold",
//                                 fontSize:"80px",
//                                 // mb: 2,
//                             }}
//                         >
//                             BEST <span style={{color:"#ffaa17"}}>GOLD</span>
//                         </Typography>
//                         <Typography
//                             variant="h3"
//                             textAlign="start"
//                             sx={{
//                                 fontWeight: "bold",
//                                 fontSize:"40px",

//                                 mb: 4,
//                             }}
//                         >
//                           STOCKING COMPANY
//                         </Typography>
//                         <Typography
//                             variant="body2"
//                             textAlign="start"
//                             sx={{
//                                 mb: 5,
//                                 lineHeight: "1.6",
//                                 color:"#ffffff"
//                             }}
//                         >
//                             We are committed to providing our customers with exceptional
//                             service while offering our employees the best training.
//                         </Typography>
//                         <Stack spacing={2} direction="row">
//                             <Button
//                                 variant="contained"
//                                 sx={{
//                                     backgroundColor: "#2A2C30",
//                                     color: "white",
//                                     fontWeight: "12px",
//                                     padding: "10px 20px",
//                                     "&:hover": {
//                                         backgroundColor: "#2A2C30",
//                                     },
//                                 }}
//                             >
//                                 Already an number
//                             </Button>
//                             <Button
//                                 variant="contained"
//                                 sx={{
//                                     backgroundColor: "#FFAA17",
//                                     color: "white",
//                                     fontWeight: "12px",
//                                     padding: "10px 20px",
//                                     "&:hover": {
//                                         backgroundColor: "#FFAA17",
//                                     },
//                                 }}
//                             >
//                                 Get Started Now
//                             </Button>

//                         </Stack>
//                     </Box>

//                     {/* Right Section */}
//                     {/* <Box
//                         sx={{
//                             borderRadius: "50%",
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             background: "#004d40",
//                             padding: "10px",
//                         }}
//                     >
//                         <img
//                             src={Logo_gold}
//                             alt="Gold Stake Logo"
//                             style={{
//                                 width: "325px",
//                                 height: "325px",
//                                 objectFit: "cover",
//                             }}
//                         />
//                     </Box> */}
//                 </Box>
//             </Container>
//         </Box>
//     );
// };

// export default LandingPage;




import React from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import home_page_Image from "../../Assets/home_page_Image.png";
import home_side_image from "../../Assets/Overlay.png"; // Ensure this is the correct path

const LandingPage = () => {
    return (
        <Box
            sx={{
                // position: "relative", // Needed for positioning child elements
                background: `linear-gradient(90deg, #000000 0%, #54545400 100%), url(${home_page_Image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "50px 0px",
                // left:"100px"
                // minHeight: "90vh", // Adjust height as needed
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Box
                    display="flex"
                    // alignItems="center"
                    justifyContent="start"
                    flexWrap="wrap"
                >
                    {/* Left Section */}
                    <Box
                        sx={{

                            color: "#fff",
                        }}
                    >
                        <Typography
                            variant="h3"
                            textAlign="start"
                            sx={{
                                fontWeight: "bold",
                                fontSize: "50px",
                                // mb:"1px"
                            }}
                        >
                            The Best Gold
                        </Typography>
                        <Typography
                            variant="h3"
                            textAlign="start"
                            sx={{
                                fontWeight: "bold",
                                fontSize: "40px",
                                mb: 2,
                            }}
                        >
                            Staking Company
                        </Typography>
                        <Typography
                            variant="body2"
                            textAlign="start"
                            sx={{
                                mb: 5,
                                lineHeight: "1.6",
                                color: "#ffffff",
                                // width:"55%",
                                maxWidth: "500px",

                                letterSpacing: "0px",
                                color: "#b0aaaa",
                                opacity: 1
                            }}
                        >
                            We are committed to providing our customers with exceptional
                            service while offering our employees the best training service while offering our employees.
                        </Typography>
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="contained"

                                sx={{
                                    // backgroundColor: "#C38F51",
                                    textTransform: "none",
                                    color: "white",
                                    fontSize: "14px",
                                    padding: "0px 40px",
                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                    "&:hover": {
                                        backgroundColor: "#C38F51",
                                    },
                                }}
                            >
                                Get Started
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    // backgroundColor: "#FFAA17",
                                    border: "1px solid #C38F51",
                                    textTransform: "none",
                                    color: "white",
                                    fontSize: "14px",
                                    padding: "0px 40px",
                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    // font: "normal normal normal 16px / 25px Roboto",
                                    letterSpacing: "0px",
                                    color: "#C38F51",
                                    // "&:hover": {
                                    //     backgroundColor: "#C38F51",
                                    // },
                                }}
                            >
                                Already An User ? Login
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default LandingPage;
