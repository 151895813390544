// // import React, { useState } from 'react';
// // import { Box, Button, Container, Grid, IconButton, TextField, Typography, Link } from '@mui/material';
// // import { Visibility, VisibilityOff } from '@mui/icons-material';
// // import { styled } from '@mui/system';
// // import login_bgImage from "../../Assets/in-signin-image.jpg"
// // import CustomInput from '../CustomInput/CustomInput';


// // const LoginForCustomer = () => {
// //   const [showPassword, setShowPassword] = useState(false);

// //   //password show
// //   const togglePasswordVisibility = () => {
// //     setShowPassword((prev) => !prev);
// //   };

// //   const handleSubmit = (event) => {
// //     event.preventDefault();
// //     // Add captcha validation and form submission logic here
// //   };

// //   return (
// //     // <Container maxWidth="lg" sx={{

// //     //   // display: 'flex', alignItems: 'center', height: "100vh",

// //     // }}>
// //     <Grid container sx={{
// //       boxShadow: 3,
// //       height: "100vh"
// //     }}>
// //       <Grid
// //         item
// //         md={7}
// //         sx={{
// //           display: { xs: 'none', md: 'block' },
// //           backgroundImage: `url(${login_bgImage})`,
// //           backgroundSize: 'cover', // Fills the container; try 'cover' instead of 'contain' to prevent gaps
// //           backgroundRepeat: "no-repeat", // 'none' may not prevent repeating; 'no-repeat' ensures it only appears once
// //           backgroundPosition: 'center', // Adjust this to 'center right' if needed for alignment
// //           boxShadow: 5,
// //           width: '100%', // Ensures the element takes full width of the container
// //           height: '100vh', // Adjusts height to full viewport, or use the parent container's height if applicable
// //         }}
// //       />
// //       <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center', p: 4, background: '#f4f4f4' }}>
// //         <Box textAlign="center" width="100%" >
// //           <Link href="index.php">
// //             <img src="https://vps97278.inmotionhosting.com/~theminsall/demo/unilevel_investment/uploads/logo/logo.png" alt="logo" width={150} />
// //           </Link>
// //           <Typography variant="h5" mt={2} mb={4}>Log into your account</Typography>
// //           <form onSubmit={handleSubmit}>
// //             <CustomInput

// //               label="Profile ID or E-Mail ID"
// //               name="profileid"

// //               custPlaceholder="Enter station name"
// //               inputType="text"
// //             // margin="normal"
// //             />
// //             <Box sx={{ position: 'relative', mt: 2 }}>
// //               <TextField
// //                 fullWidth
// //                 variant="outlined"
// //                 label="Password"
// //                 type={showPassword ? 'text' : 'password'}
// //                 name="password"
// //                 required
// //               />
// //               <IconButton
// //                 aria-label="toggle password visibility"
// //                 onClick={togglePasswordVisibility}
// //                 edge="end"
// //                 sx={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }}
// //               >
// //                 {showPassword ? <Visibility /> : <VisibilityOff />}
// //               </IconButton>
// //             </Box>

// //             {/* ReCaptcha Placeholder */}
// //             <Box my={3}>
// //               {/* Insert actual ReCaptcha component here */}
// //               <div>ReCaptcha Placeholder</div>
// //             </Box>

// //             <Box display="flex" justifyContent="space-between" mb={2}>
// //               <Link href="forgot.php" underline="none">
// //                 Forgot password?
// //               </Link>
// //             </Box>

// //             <Button
// //               fullWidth
// //               type="submit"
// //               variant="contained"
// //               color="primary"
// //               sx={{ borderRadius: 2, py: 1.5 }}
// //             >
// //               Sign in
// //             </Button>
// //           </form>
// //           <Typography variant="body2" mt={2}>
// //             Don't have an account? <Link href="register.php">Register here</Link>
// //           </Typography>
// //         </Box>
// //       </Grid>
// //     </Grid>
// //     //  </Container>
// //   );
// // };

// // export default LoginForCustomer;



// import React, { useState } from 'react';
// import { Box, Button, Grid, IconButton, TextField, Typography, Link } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { Formik, Field, Form } from 'formik';
// import * as Yup from 'yup';  // Optional: For validation
// import login_bgImage from "../../Assets/in-signin-image.jpg";
// import CustomInput from '../CustomInput/CustomInput';
// import CustomInputLogin from '../CustomInputLogin/CustomInputLogin';

// const LoginForCustomer = () => {
//   const [showPassword, setShowPassword] = useState(false);

//   // Toggle password visibility
//   const togglePasswordVisibility = () => {
//     setShowPassword((prev) => !prev);
//   };

//   const initialValues = {
//     profileid: '',
//     password: ''
//   };

//   // Optional: validation schema using Yup
//   const validationSchema = Yup.object({
//     profileid: Yup.string().required('Profile ID or E-Mail ID is required'),
//     password: Yup.string().required('Password is required')
//   });

//   const handleSubmit = (values) => {
//     // Add form submission logic
//     console.log(values);  // You can log the form values or handle API calls here
//   };

//   return (
//     <Grid container sx={{ boxShadow: 3, height: '100vh' }}>
//       <Grid
//         item
//         md={7}
//         sx={{
//           display: { xs: 'none', md: 'block' },
//           backgroundImage: `url(${login_bgImage})`,
//           backgroundSize: 'cover',
//           backgroundRepeat: "no-repeat",
//           backgroundPosition: 'center',
//           boxShadow: 5,
//           width: '100%',
//           height: '100vh',
//         }}
//       />
//       <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center',justifyContent:"center", p: 4, background: '#f4f4f4', width: "100%" }}>
//         <Box sx={{ width:"100%", display: "flex", flexDirection: "column" ,  justifyContent:"center"}}>

//             <Box sx={{textAlign:"center"}}>

//               <img src="https://vps97278.inmotionhosting.com/~theminsall/demo/unilevel_investment/uploads/logo/logo.png" alt="logo" width={150} />
//             </Box>

//             <Typography 
//             variant="h5" textAlign="center" mt={2} mb={4}>Log into your account</Typography>

//             <Formik
//               initialValues={initialValues}
//               validationSchema={validationSchema}
//               onSubmit={handleSubmit}
//             >
//               {({ values, handleChange, handleBlur, errors, touched }) => (
//                 <Form>
//                   <CustomInputLogin
//                     // label="Profile ID or E-Mail ID"
//                     name="profileid"
//                     // value={values.profileid}
//                     // onChange={handleChange}
//                     // onBlur={handleBlur}
//                     custPlaceholder="Enter Profile ID or E-Mail ID"
//                     inputType="text"
//                   />


//                   <Box sx={{ position: 'relative', mt: 2 }}>
//                     <TextField
//                       fullWidth
//                       variant="outlined"
//                       label="Password"
//                       type={showPassword ? 'text' : 'password'}
//                       name="password"
//                       value={values.password}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       required
//                     />
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={togglePasswordVisibility}
//                       edge="end"
//                       sx={{ position: 'absolute', right: 8, top: '50%', transform: 'translateY(-50%)' }}
//                     >
//                       {showPassword ? <Visibility /> : <VisibilityOff />}
//                     </IconButton>
//                   </Box>


//                   {/* ReCaptcha Placeholder */}
//                   <Box my={3} textAlign="center">
//                     {/* Insert actual ReCaptcha component here */}
//                     <div>ReCaptcha Placeholder</div>
//                   </Box>

//                   <Box display="flex" justifyContent="space-between" mb={2} alignItems="center">
//                     <Link href="forgot.php" underline="none" textAlign="center">
//                       Forgot password?
//                     </Link>
//                   </Box>

//                   <Button
//                     fullWidth
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     sx={{ borderRadius: 2, py: 1.5 }}
//                   >
//                     Sign in
//                   </Button>
//                 </Form>
//               )}
//             </Formik>

//             <Typography variant="body2" mt={2}>
//               Don't have an account? <Link href="register.php">Register here</Link>
//             </Typography>
//           </Box>

//       </Grid>
//     </Grid>
//   );
// };

// export default LoginForCustomer;





import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, TextField, Typography, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';  // Optional: For validation
import login_bgImage from "../../Assets/login_bgImage.png";
import CustomInput from '../CustomInput/CustomInput';
import CustomInputLogin from '../CustomInputLogin/CustomInputLogin';
import logo_login_image from "../../Assets/logo_login_image.png";


const LoginComponent = () => {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const initialValues = {
    emailid: '',
    password: ''
  };

  // Optional: validation schema using Yup
  const validationSchema = Yup.object({
    emailid: Yup.string().required('Email Id is reuired'),
    password: Yup.string().required('Password is required')
  });

  const handleSubmit = (values) => {
    // Add form submission logic
    console.log(values, "values");  // You can log the form values or handle API calls here
  };

  return (
    <Grid container sx={{ boxShadow: 3, height: '100vh' }}>
      <Grid
        item
        md={7}
        sx={{
          display: { xs: 'none', md: 'flex' },
          backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 11%, rgba(84, 84, 84, 0) 100%), url(${login_bgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          boxShadow: 5,
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', // Ensures content stacks vertically
          // mb:2
        }}
      >
        <img
          src={logo_login_image}
          alt="logo"
          width="213px"
          height="61px"
        />
        <Box
          display="flex"
          // alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          mt={2}
        >
          {/* Left Section */}
          <Box
            sx={{

              color: "#fff",
            }}
          >
            <Typography
              variant="h3"
              textAlign="center"
              sx={{
                fontWeight: "bold",
                fontSize: "50px",
                mb: "1px"
              }}
            >
              The Best Gold
            </Typography>
            <Typography
              variant="h3"
              textAlign="center"
              sx={{
                fontWeight: "bold",
                fontSize: "35px",
                mb: 2,
              }}
            >
              Staking Company
            </Typography>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                // mb: 5,
                lineHeight: "1.6",
                color: "#ffffff",
                // width:"55%",
                maxWidth: "500px",

                letterSpacing: "0px",
                color: "#b0aaaa",
                opacity: 1
              }}
            >
              We are committed to providing our customers with exceptional
              service while offering our employees the best training service while offering our employees.
              We are committed to providing our customers with exceptional
              service while offering our employees
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
          background: '#FFFFFF',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 350, // Added for consistent width
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Aligns all children centrally
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            sx={{ fontSize: "30px", fontWeight: "600", mb: 4 }}
          >
            Sign In
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, errors, touched }) => (
              <Form style={{ width: '100%' }}> {/* Ensures form takes full width */}
                <Box mb={1}>
                  <CustomInput
                    name="emailid"
                    label="Email Id"
                    custPlaceholder="Enter Email Id"
                    inputType="text"
                  />
                </Box>
                <Box mb={1}>
                  <CustomInput
                    name="password"
                    label="Password"
                    custPlaceholder="Enter Password"
                    inputType="password"

                  />
                </Box>

                <Box
                  display="flex"
                  justifyContent="end"
                  alignItems="end"
                  width="100%"
                  mb={3}
                  color="#C38F51"
                >
                  <Link href="forgot.php" underline="none" color='#C38F51'>
                    Forgot password?
                  </Link>
                </Box>

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"

                  sx={{
                    borderRadius: 23,
                    py: "7px",
                    color:"white",

                    backgroundColor: "#C38F51"

                  }}
                >
                  Sign in
                </Button>
              </Form>
            )}
          </Formik>

          <Typography
            variant="body2"
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center', // Ensure centered text
            }}
          >
            Don't have an account?&nbsp;
            <Typography  sx={{color:"#C38F51"}} underline="none">Register here</Typography>
          </Typography>
        </Box>
      </Grid>

    </Grid >
  );
};

export default LoginComponent;
