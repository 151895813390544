// import React, { useState } from "react";
// import {
//     Box,
//     Grid,
//     Typography,
//     TextField,
//     MenuItem,
//     Button,
//     InputAdornment,
// } from "@mui/material";
// import { AccountCircle, Email, Phone, Public } from "@mui/icons-material";
// import CustomInputLogin from "../../Components/CustomInputLogin/CustomInputLogin";

// const countries = [
//     { value: "India", label: "India" },
//     { value: "USA", label: "USA" },
//     { value: "UK", label: "UK" },
//     { value: "Australia", label: "Australia" },
// ];

// const ContactUsection = () => {


//     return (
//         <Grid>
//             <Box sx={{ maxWidth: '80%', margin: '0 auto' }}>
//                 <Typography variant="h4" textAlign="center" gutterBottom fontSize="30px" sx={{ mb: 2 }}>
//                     <span style={{ fontWeight: '800' }}>Get in Touch</span>
//                 </Typography>
//                 <Typography
//                     sx={{
//                         paddingBottom: 4,
//                         maxWidth: '900px',
//                         margin: '0 10px',
//                         textAlign: 'center',
//                         color: '#b0aaaa',
//                         fontSize: '14px',

//                     }}
//                 >
//                     Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//                     Lorem Ipsum has been the industry’s standard dummy text ever since the
//                     1500s, when an unknown printer took a galley of type and scrambled it to
//                     make a type specimen book.
//                 </Typography>


//             </Box>
//             <Grid container spacing={4} sx={{ maxWidth: "90%", margin: "0 auto" }}>
//                 {/* Left Section */}
//                 <Grid item xs={12} md={5}>
//                     <Typography variant="h4" fontWeight="bold" gutterBottom>
//                         Contact
//                     </Typography>
//                     <Typography sx={{ mb: 2 }}>
//                         📍 6th Main Road, V Block, Anna Nagar, Chennai - 600040
//                     </Typography>
//                     <Typography sx={{ mb: 2 }}>📞 +91 9876543210</Typography>
//                     <Typography>📧 info@goldstack.com</Typography>
//                 </Grid>

//                 {/* Right Section */}
//                 <Grid item xs={12} md={7}>
//                     <Box
//                         component="form"
//                         // onSubmit={handleSubmit}
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             gap: 2,
//                         }}
//                     >

//                         <CustomInputLogin
//                             name="fullname"
//                             // label="Name"
//                             custPlaceholder="Full Name"
//                             inputType="text"
//                         />

//                         <CustomInputLogin
//                             name="email"
//                             // label="Name"
//                             custPlaceholder="Email"
//                             inputType="email"
//                         />
//                         <CustomInputLogin
//                             name="phonenumber"
//                             // label="Name"
//                             custPlaceholder="Phone Number"
//                             inputType="number"
//                         />
//                         <CustomInputLogin
//                             name="message"
//                             // label="Name"
//                             custPlaceholder="Message"
//                             inputType="textarea"
//                         />





//                         {/* Message */}


//                         {/* Submit Button */}
//                         <Button
//                             type="submit"
//                             variant="contained"
//                             sx={{
//                                 backgroundColor: "#d8a35e",
//                                 color: "#fff",
//                                 fontWeight: "bold",
//                                 "&:hover": { backgroundColor: "#c28f4a" },
//                             }}
//                         >
//                             Submit
//                         </Button>
//                     </Box>
//                 </Grid>
//             </Grid>
//         </Grid>
//     );
// };

// export default ContactUsection;



import React from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInputLogin from "../../Components/CustomInputLogin/CustomInputLogin";
import CustomDropdownMui from "../../Components/CustomDropDown/CustomDropdown";
import user_icon_image from "../../Assets/User_icon_image.svg"
import email_input_icon from "../../Assets/email_input_icon.svg"

import country_icon from "../../Assets/country_icon.svg"

import phone_input_icon from "../../Assets/phone_input_icon.svg"

import location_icon from "../../Assets/location_icon.svg"

import call_icon from "../../Assets/call_icon.svg"

import mail_icon from "../../Assets/mail_icon.svg"


const countries = [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "UK", label: "UK" },
    { value: "Australia", label: "Australia" },
];

// Validation schema using Yup
const validationSchema = Yup.object({
    fullname: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phonenumber: Yup.string()
        .matches(/^[0-9]+$/, "Must be a number")
        .min(10, "Must be at least 10 digits")
        .required("Phone Number is required"),
    message: Yup.string().required("Message is required"),
    countries: Yup.string().required("Countries is required"),

});

const ContactUsection = () => {
    const initialValues = {
        fullname: "",
        email: "",
        countries: "",
        phonenumber: "",
        message: "",
    };

    const handleSubmit = (values, { resetForm }) => {
        console.log("Form Values:", values);
        resetForm();
    };

    return (
        <Grid>
            <Box sx={{ maxWidth: "80%", margin: "0 auto" }}>
                <Typography
                    variant="h4"
                    textAlign="center"
                    gutterBottom
                    fontSize="30px"
                    sx={{ mb: 2 }}
                >
                    <span style={{ fontWeight: "800" }}>Get in Touch</span>
                </Typography>
                <Typography
                    sx={{
                        paddingBottom: 4,
                        maxWidth: "900px",
                        margin: "0 10px",
                        textAlign: "center",
                        color: "#b0aaaa",
                        fontSize: "14px",
                    }}
                >
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry’s standard dummy text
                    ever since the 1500s.
                </Typography>
            </Box>
            <Grid container spacing={4} sx={{ maxWidth: "90%", margin: "0 auto", pb: "30px" }}>
                {/* Left Section */}
                <Grid item xs={12} md={7}>
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                        Contact
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <img src={location_icon} alt="Location Icon" style={{ marginRight: "8px" }} />
                        <Typography
                            sx={{ maxWidth: "250px", fontSize: "14px", color: "#3B4056" }}
                        >
                            6th Main Road, V Block, Anna Nagar, Chennai - 600040
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <img src={mail_icon} alt="Call Icon" style={{ marginRight: "8px" }} />
                        <Typography
                            sx={{ maxWidth: "200px", fontSize: "14px", color: "#3B4056" }}

                        >+91 9876543210</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={call_icon} alt="Mail Icon" style={{ marginRight: "8px" }} />
                        <Typography
                            sx={{ maxWidth: "300px", fontSize: "14px", color: "#3B4056" }}

                        >info@goldstack.com</Typography>
                    </Box>

                </Grid>

                {/* Right Section */}
                <Grid item xs={12} md={5}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, handleChange, handleBlur }) => (
                            <Form>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <Grid xs={12}>
                                        <CustomInputLogin
                                            name="fullname"
                                            custPlaceholder="Full Name"
                                            inputType="text"
                                            image={user_icon_image}

                                        />

                                    </Grid>
                                    <Grid xs={12}>
                                        <CustomInputLogin
                                            name="email"
                                            custPlaceholder="Email"
                                            inputType="email"
                                            image={email_input_icon}

                                        />
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <CustomDropdownMui
                                                name="countries"
                                                options={countries}
                                                custPlaceholder="Select operator"
                                                image={country_icon}

                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <CustomInputLogin
                                                name="phonenumber"
                                                custPlaceholder="Phone Number"
                                                inputType="number"
                                                image={phone_input_icon}

                                            />
                                        </Grid>
                                    </Grid>


                                    <CustomInputLogin
                                        name="message"
                                        custPlaceholder="Message"
                                        inputType="textarea"

                                    />

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#d8a35e",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            "&:hover": { backgroundColor: "#c28f4a" },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContactUsection;
