






import React from 'react';
// import LandingPage from './LandingPage';
// import ProsperitySteps from '../ProperitySteps/ProperitySteps';
// import OfferSection from '../OfferSection/OfferSection';
// import FAQSection from '../FAQSection/FAQSection';
// import Footer from '../Footer/Footer';
// import ReferSection from '../ReferSection/ReferSection';
// import ContactUsection from '../ContactUsection/ContactUsection';
import { IconButton, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import MyAppBar from '../../Components/AppBar/CustomAppBar';
import Footer from '../../Footer/Footer';
// import StakeNowMainPage from '../StakeNowMainPage/StakeNowMainPage';
import MyAppBar from '../../../Components/AppBar/CustomAppBar';

import AddtoCartMainPage from './AddtoCartMainPage';
import AddToCartSchemesById from './AddToCartSchemesById';
import GoldInvestment from './GoldInvestment';
import GoldInfoAccordion from './GoldInfoAccordion';
// import Pusrchase_GoldStake from '../Purchase&GoldStack/Pusrchase_GoldStake';
// import ReferSection from '../../ReferSection/ReferSection';
// import PurchaseSchemes from '../Purchase&Schemes/PurchaseSchemes';
// import ReferralsMainPage from '../ReferralsMainPage/ReferralsMainPage';


const AddToCartHome = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (

        <div style={{ position: "relative" }}>
            <MyAppBar />

            <AddtoCartMainPage />
            <AddToCartSchemesById />
            <GoldInvestment />
            {/* <GoldInfoAccordion /> */}

            <Footer />


        </div>
    )
}


export default AddToCartHome;



