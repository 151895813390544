



import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import { CardContent, Card, Divider } from "@mui/material";

const DashedArrow = styled("div")(() => ({
    position: "absolute",
    borderTop: "2px dashed black",
    width: "calc(33% - 40px)", // Adjust the width for proper spacing
    top: "50%",
    transform: "translateY(-50%)",
    left: "calc(33% - 20px)", // Adjust for spacing between cards
    "&:after": {
        content: '""',
        position: "absolute",
        top: "-6px",
        right: "-12px",
        width: "0",
        height: "0",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderTop: "8px solid black",
    },
}));

const HowItWorks = () => {
    const steps = [
        {
            title: "Lorem Ipsum",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
            icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
        },
        {
            title: "Lorem Ipsum",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
            icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
        },
        {
            title: "Lorem Ipsum",
            description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
            icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
        },
    ];

    return (
        <>
            <Box sx={{ p: 4 }}>
                <Box sx={{ textAlign: "center", mb: 2 }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ paddingBottom: "5px", fontSize: "30px" }}
                    >
                        <span style={{ color: "black", fontWeight: "800" }}>Three</span> Steps For Prosperity
                    </Typography>
                    <Typography
                        sx={{
                            // paddingBottom: "5px",
                            maxWidth: "800px",
                            margin: "0 auto",
                            textAlign: "center",
                            color: "#b0aaaa",
                            fontSize: "14px",
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book.
                    </Typography>
                </Box>


                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // mt: 2,
                    }}
                >
                    <Typography sx={{ fontSize: "17px", fontWeight: "400", mb: 2, color: "#C38F51" }}>Double Layered Benefits On Our Exclusive Referral Plan</Typography>
                    {/* First Card */}
                    <Card
                        sx={{
                            width: "500px",
                            backgroundColor: "#F7F7F7",
                            textAlign: "center",
                            boxShadow: "none",

                            mb: 2,
                        }}
                        elevation={2}
                    >
                        <CardContent>
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px", color: "#C38F51", mb: 1 }}>
                                Diamond Referrer
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: "14px", color: "#7A7A7A" }}>
                                You will be a Diamond Referrer staked with Diamond Referrer worth
                                of Gold for every 1000 USD Purchase by your referral.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Dashed Line */}
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            borderLeftWidth: "2px",
                            borderColor: "#ccc",
                            height: "50px",
                            mb: 2,
                        }}
                    />

                    {/* Second Card */}
                    <Card
                        sx={{
                            width: "500px",
                            backgroundColor: "#F7F7F7",
                            // border: "2px solid #2196f3",
                            textAlign: "center",
                            boxShadow: "none",

                            mb: 2,
                        }}
                        elevation={4}
                    >
                        <CardContent>
                            {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}> */}
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px", color: "#C38F51", mb: 1 }}>

                                Diamond Referrer
                            </Typography>
                            {/* <Typography variant="body2"> */}
                            <Typography variant="body2" sx={{ fontSize: "14px", color: "#7A7A7A" }}>

                                Your Referral will be a Diamond Referrer staked with Diamond
                                Referrer worth of Gold for every 1000 USD Purchase by your
                                referral's referral.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Dashed Line */}
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            borderLeftWidth: "2px",
                            borderColor: "red",
                            height: "50px",
                            mb: 2,
                        }}
                    />

                    {/* Third Card */}
                    <Card
                        sx={{
                            width: "500px",
                            backgroundColor: "#F7F7F7",
                            textAlign: "center",
                            boxShadow: "none",
                            mb: 2,
                        }}
                        elevation={2}
                    >
                        <CardContent>
                            {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}> */}
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px", color: "#C38F51", mb: 1 }}>

                                Ruby Referrer
                            </Typography>
                            {/* <Typography variant="body2"> */}
                            <Typography variant="body2" sx={{ fontSize: "14px", color: "#7A7A7A" }}>

                                You will be a Ruby Referrer staked with Ruby Referrer worth of Gold
                                for every 1000 USD Purchase by your referral’s referral.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>


                {/* Add the dashed arrows between the cards */}
                {/* {steps.slice(0, -1).map((_, index) => (
            <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
          ))} */}
                {/* </Box> */}
            </Box>
        </>
    );
};

export default HowItWorks;


