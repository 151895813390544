



import React from "react";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { styled } from "@mui/system";

const ProsperityCard = ({ icon, title, description }) => {
  return (
    <Box textAlign="center">
      <Avatar
        sx={{
          bgcolor: "#D49C63",
          width: 80,
          height: 80,
          margin: "0 auto",
        }}
      >
        {icon}
      </Avatar>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
        {description}
      </Typography>
    </Box>
  );
}

export default ProsperityCard;