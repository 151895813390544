import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    Grid,
    Divider,
} from "@mui/material";
import { IoIosInformation } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import cart_icon from "../../Assets/cart_icon.svg"
import { AiFillThunderbolt } from "react-icons/ai";



// const schemes = [
//   {
//     name: "Scheme Name 1",
//     valueOfPurchase: "$1000",
//     purchase: "13.8 Grm",
//     maturity: "2 Years",
//     lockIn: "24 Month's",
//     benefit: "6%* Per Quarter",
//     closingDate: "15/Mar/2025",
//     backgroundImage: "gold-card-1", // replace with the background image URL
//   },
//   {
//     name: "Scheme Name 2",
//     valueOfPurchase: "$10000",
//     purchase: "138 Grm",
//     maturity: "2 Years",
//     lockIn: "24 Month's",
//     benefit: "6%* Per Quarter",
//     closingDate: "15/Mar/2025",
//     backgroundImage: "gold-card-2", // replace with the background image URL
//   },
// ];

const SchemeCard = ({ schemes }) => {
    const Navigate = useNavigate()
    const handleNavigate = () => {
        Navigate("/Add-to-cart")
    }

    console.log(schemes, "schemessssss")
    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={4}>
                {schemes.map((scheme, index) => (
                    <Grid item md={6} key={index}>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", marginBottom: 1 }}
                        >
                            {scheme.name}
                        </Typography>
                        <Card
                            sx={{
                                borderRadius: 2,
                                backgroundImage: `url(${scheme.backgroundImage})`,
                                backgroundSize: "cover",
                                objectFit: "contain",
                                color: "white",
                                p: "20px 50px",
                                height: "250px"
                            }}
                        >
                            <CardContent sx={{ padding: "0px" }}>


                                <Box
                                    display="flex" justifyContent="space-between" mb={1}

                                >
                                    <Box
                                    //  display="flex" justifyContent="space-between" 
                                    >
                                        <Typography sx={{ fontSize: "12px", color: "#FFFFFF", mb: "2px" }}>Value of Purchase</Typography>
                                        <Typography sx={{ fontSize: "16px", color: "#C38F51", fontWeight: "600" }}>{scheme.valueOfPurchase}</Typography>
                                    </Box>

                                    <Box
                                    // display="flex" justifyContent="space-between"
                                    >
                                        <Typography sx={{ fontSize: "12px", color: "#FFFFFF", mb: "2px" }}>Purchase</Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "16px",
                                                color: "#C38F51",
                                                fontWeight: "600",
                                            }}
                                        >
                                            {scheme.purchase}
                                            <span
                                                style={{
                                                    background: "#FF8800",
                                                    borderRadius: "50%",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "10px", // Set a fixed width
                                                    height: "10px", // Set a fixed height
                                                    marginLeft: "1px", // Optional spacing between text and icon
                                                }}
                                            >
                                                <IoIosInformation style={{ fontSize: "12px", color: "white" }} />
                                            </span>
                                        </Typography>

                                        {/* <Typography sx={{ borderRadius:"50%",bgcolor:"red" }}></Typography> */}

                                    </Box>
                                </Box>
                                <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }} />
                                <Box
                                    display="flex" justifyContent="space-between" mt={1}

                                >
                                    <Box
                                    // display="flex" justifyContent="space-between" mb={1}

                                    >
                                        <Typography sx={{ fontSize: "12px", color: "#FFFFFF", mb: "2px" }}>Matures at</Typography>
                                        <Typography sx={{ fontSize: "16px", color: "#C38F51", fontWeight: "600" }}>{scheme.maturity}

                                            <span
                                                style={{
                                                    background: "#FF8800",
                                                    borderRadius: "50%",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "10px", // Set a fixed width
                                                    height: "10px", // Set a fixed height
                                                    marginLeft: "1px", // Optional spacing between text and icon
                                                }}
                                            >
                                                <IoIosInformation style={{ fontSize: "12px", color: "white" }} />
                                            </span>
                                        </Typography>
                                    </Box>

                                    <Box
                                    // display="flex" justifyContent="space-between" mb={2}
                                    >
                                        <Typography sx={{ fontSize: "12px", color: "#FFFFFF", mb: "2px" }}>Lock-in</Typography>
                                        <Typography sx={{ fontSize: "16px", color: "#C38F51", fontWeight: "600" }}>{scheme.lockIn}</Typography>
                                    </Box>

                                </Box>
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography
                                        sx={{
                                            marginTop: 2,
                                            // fontWeight: "bold", 
                                            fontSize: "16px"
                                        }}
                                    >
                                        Estimated Reap Benefit
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#1FC45B",
                                            fontSize: "25px",

                                            fontWeight: "bold",

                                            // marginBottom: 1,
                                        }}
                                    >
                                        {scheme.benefit} <span style={{ color: "white", fontSize: "13px" }}>Per Quarter</span>
                                    </Typography>
                                    {/* <Link fontSize="10px" color="white" > * Read Offer Documents</Link> */}
                                    <Link
                                        style={{ fontSize: "10px", color: "white" }}
                                    >
                                        * Read Offer Documents
                                    </Link>

                                </Box>
                                <Box display="flex" justifyContent="space-around" mt={2}>
                                    <Button
                                        onClick={handleNavigate}
                                        variant="contained"
                                        startIcon={<img src={cart_icon} style={{ height: "14px" }}></img>}
                                        // sx={{
                                        //     backgroundColor: "black",
                                        //     borderRadius:"23px",
                                        //     "&:hover": { backgroundColor: "grey" },
                                        // }}
                                        sx={{
                                            // backgroundColor: "#C38F51",
                                            textTransform: "none",
                                            color: "white",
                                            fontSize: "12px",
                                            padding: "0px 20px",
                                            minHeight: "30px",
                                            borderRadius: "23px",
                                            background: "#252525 0% 0% no-repeat padding-box",
                                            // "&:hover": {
                                            //     backgroundColor: "#C38F51",
                                            // },
                                        }}
                                    >
                                        Add to Cart
                                    </Button>
                                    <Button
                                        variant="contained"
                                        startIcon={<AiFillThunderbolt sx={{ fontSize: "12px", color: "white" }} />}
                                        sx={{
                                            // backgroundColor: "#C38F51",
                                            textTransform: "none",
                                            color: "white",
                                            fontSize: "12px",
                                            padding: "0px 20px",
                                            minHeight: "30px",
                                            borderRadius: "23px",
                                            background: "#C38F51 0% 0% no-repeat padding-box",
                                            // "&:hover": {
                                            //     backgroundColor: "#C38F51",
                                            // },
                                        }}
                                    >
                                        Buy Now
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                        <Typography
                            variant="subtitle2"
                            align="end"
                            sx={{
                                marginTop: 0.5, color: "#7A7A7A", fontSize: "12px"

                            }}
                        >
                            Scheme Closes on: -<span style={{ color: "#F53E5C" }}>{scheme.closingDate}</span>
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SchemeCard;
