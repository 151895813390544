// // // // // // import * as React from 'react';
// // // // // // import AppBar from '@mui/material/AppBar';
// // // // // // import Box from '@mui/material/Box';
// // // // // // import Toolbar from '@mui/material/Toolbar';
// // // // // // import IconButton from '@mui/material/IconButton';
// // // // // // import Typography from '@mui/material/Typography';
// // // // // // import Menu from '@mui/material/Menu';
// // // // // // import MenuIcon from '@mui/icons-material/Menu';
// // // // // // import Container from '@mui/material/Container';
// // // // // // import Avatar from '@mui/material/Avatar';
// // // // // // import Button from '@mui/material/Button';
// // // // // // import Tooltip from '@mui/material/Tooltip';
// // // // // // import MenuItem from '@mui/material/MenuItem';
// // // // // // import AdbIcon from '@mui/icons-material/Adb';
// // // // // // import logo_image_new from "../../Assets/logo_image_new.png"

// // // // // // const pages = ['Home', 'About us', 'Pages',"Services","Portfolio","Shop","Blog","Contact"];
// // // // // // const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// // // // // // function CustomAppBar() {
// // // // // //   const [anchorElNav, setAnchorElNav] = React.useState(null);
// // // // // //   const [anchorElUser, setAnchorElUser] = React.useState(null);

// // // // // //   const handleOpenNavMenu = (event) => {
// // // // // //     setAnchorElNav(event.currentTarget);
// // // // // //   };
// // // // // //   const handleOpenUserMenu = (event) => {
// // // // // //     setAnchorElUser(event.currentTarget);
// // // // // //   };

// // // // // //   const handleCloseNavMenu = () => {
// // // // // //     setAnchorElNav(null);
// // // // // //   };

// // // // // //   const handleCloseUserMenu = () => {
// // // // // //     setAnchorElUser(null);
// // // // // //   };

// // // // // //   return (

// // // // // //     <AppBar position="fixed" sx={{bgcolor:"#00675B",borderBottom:"1px solid #b1a8a8"}}>
// // // // // //       <Container maxWidth="xl">
// // // // // //         <Toolbar 
// // // // // //         //  sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}
// // // // // //          >
// // // // // //           {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
// // // // // //           <img src={logo_image_new} height="90px" width="90px"></img>
// // // // // //           <Typography
// // // // // //             variant="h6"
// // // // // //             noWrap
// // // // // //             component="a"
// // // // // //             href="#app-bar-with-responsive-menu"
// // // // // //             sx={{
// // // // // //               mr: 2,
// // // // // //               display: { xs: 'none', md: 'flex' },
// // // // // //               fontFamily: 'monospace',
// // // // // //               fontWeight: 700,
// // // // // //               letterSpacing: '.3rem',
// // // // // //               color: 'inherit',
// // // // // //               textDecoration: 'none',
// // // // // //             }}
// // // // // //           >
// // // // // //             LOGO
// // // // // //           </Typography>

// // // // // //           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
// // // // // //             <IconButton
// // // // // //               size="large"
// // // // // //               aria-label="account of current user"
// // // // // //               aria-controls="menu-appbar"
// // // // // //               aria-haspopup="true"
// // // // // //               onClick={handleOpenNavMenu}
// // // // // //               color="inherit"
// // // // // //             >
// // // // // //               <MenuIcon />
// // // // // //             </IconButton>
// // // // // //             <Menu
// // // // // //               id="menu-appbar"
// // // // // //               anchorEl={anchorElNav}
// // // // // //               anchorOrigin={{
// // // // // //                 vertical: 'bottom',
// // // // // //                 horizontal: 'left',
// // // // // //               }}
// // // // // //               keepMounted
// // // // // //               transformOrigin={{
// // // // // //                 vertical: 'top',
// // // // // //                 horizontal: 'left',
// // // // // //               }}
// // // // // //               open={Boolean(anchorElNav)}
// // // // // //               onClose={handleCloseNavMenu}
// // // // // //               sx={{ display: { xs: 'block', md: 'none' } }}
// // // // // //             >
// // // // // //               {pages.map((page) => (
// // // // // //                 <MenuItem key={page} onClick={handleCloseNavMenu}>
// // // // // //                   <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
// // // // // //                 </MenuItem>
// // // // // //               ))}
// // // // // //             </Menu>
// // // // // //           </Box>
// // // // // //           <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
// // // // // //           <Typography
// // // // // //             variant="h5"
// // // // // //             noWrap
// // // // // //             component="a"
// // // // // //             href="#app-bar-with-responsive-menu"
// // // // // //             sx={{
// // // // // //               mr: 2,
// // // // // //               display: { xs: 'flex', md: 'none' },
// // // // // //               flexGrow: 1,
// // // // // //               fontFamily: 'monospace',
// // // // // //               fontWeight: 700,
// // // // // //               letterSpacing: '.3rem',
// // // // // //               color: 'inherit',
// // // // // //               textDecoration: 'none',
// // // // // //             }}
// // // // // //           >
// // // // // //             LOGO
// // // // // //           </Typography>
// // // // // //           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },justifyContent:"center" }}>
// // // // // //             {pages.map((page) => (
// // // // // //               <Button
// // // // // //                 key={page}
// // // // // //                 onClick={handleCloseNavMenu}
// // // // // //                 sx={{ my: 2, color: 'white', display: 'block' }}
// // // // // //               >
// // // // // //                 {page}
// // // // // //               </Button>
// // // // // //             ))}
// // // // // //           </Box>
// // // // // //           <Box sx={{ flexGrow: 0 }}>
// // // // // //             <Tooltip title="Open settings">
// // // // // //               <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
// // // // // //                 <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
// // // // // //               </IconButton>
// // // // // //             </Tooltip>
// // // // // //             <Menu
// // // // // //               sx={{ mt: '45px' }}
// // // // // //               id="menu-appbar"
// // // // // //               anchorEl={anchorElUser}
// // // // // //               anchorOrigin={{
// // // // // //                 vertical: 'top',
// // // // // //                 horizontal: 'right',
// // // // // //               }}
// // // // // //               keepMounted
// // // // // //               transformOrigin={{
// // // // // //                 vertical: 'top',
// // // // // //                 horizontal: 'right',
// // // // // //               }}
// // // // // //               open={Boolean(anchorElUser)}
// // // // // //               onClose={handleCloseUserMenu}
// // // // // //             >
// // // // // //               {settings.map((setting) => (
// // // // // //                 <MenuItem key={setting} onClick={handleCloseUserMenu}>
// // // // // //                   <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
// // // // // //                 </MenuItem>
// // // // // //               ))}
// // // // // //             </Menu>
// // // // // //           </Box>
// // // // // //         </Toolbar>
// // // // // //       </Container>
// // // // // //     </AppBar>
// // // // // //   );
// // // // // // }
// // // // // // export default CustomAppBar;



// // // // // import * as React from 'react';
// // // // // import { NavLink, Outlet } from 'react-router-dom';
// // // // // import AppBar from '@mui/material/AppBar';
// // // // // import Box from '@mui/material/Box';
// // // // // import Toolbar from '@mui/material/Toolbar';
// // // // // import IconButton from '@mui/material/IconButton';
// // // // // import Typography from '@mui/material/Typography';
// // // // // import Menu from '@mui/material/Menu';
// // // // // import MenuIcon from '@mui/icons-material/Menu';
// // // // // import Container from '@mui/material/Container';
// // // // // import Avatar from '@mui/material/Avatar';
// // // // // import Button from '@mui/material/Button';
// // // // // import Tooltip from '@mui/material/Tooltip';
// // // // // import MenuItem from '@mui/material/MenuItem';
// // // // // import AdbIcon from '@mui/icons-material/Adb';
// // // // // import logo_image_new from '../../Assets/logo_image_new.png';

// // // // // const pages = [
// // // // //   { name: 'Home', path: '/' },
// // // // //   { name: 'About us', path: '/about' },
// // // // //   { name: 'Pages', path: '/pages' },
// // // // //   { name: 'Services', path: '/services' },
// // // // //   { name: 'Portfolio', path: '/portfolio' },
// // // // //   { name: 'Shop', path: '/shop' },
// // // // //   { name: 'Blog', path: '/blog' },
// // // // //   { name: 'Contact', path: '/contact' },
// // // // // ];

// // // // // const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// // // // // function CustomAppBar() {
// // // // //   const [anchorElNav, setAnchorElNav] = React.useState(null);
// // // // //   const [anchorElUser, setAnchorElUser] = React.useState(null);

// // // // //   const handleOpenNavMenu = (event) => {
// // // // //     setAnchorElNav(event.currentTarget);
// // // // //   };

// // // // //   const handleOpenUserMenu = (event) => {
// // // // //     setAnchorElUser(event.currentTarget);
// // // // //   };

// // // // //   const handleCloseNavMenu = () => {
// // // // //     setAnchorElNav(null);
// // // // //   };

// // // // //   const handleCloseUserMenu = () => {
// // // // //     setAnchorElUser(null);
// // // // //   };

// // // // //   return (
// // // // //     <>
// // // // //       <AppBar position="fixed" sx={{ bgcolor: '#00675B', borderBottom: '1px solid #b1a8a8' }}>
// // // // //         <Container maxWidth="xl">
// // // // //           <Toolbar>
// // // // //             <img src={logo_image_new} height="90px" width="90px" alt="Logo" />
// // // // //             <Typography
// // // // //               variant="h6"
// // // // //               noWrap
// // // // //               component="a"
// // // // //               href="#"
// // // // //               sx={{
// // // // //                 mr: 2,
// // // // //                 display: { xs: 'none', md: 'flex' },
// // // // //                 fontFamily: 'monospace',
// // // // //                 fontWeight: 700,
// // // // //                 letterSpacing: '.3rem',
// // // // //                 color: 'inherit',
// // // // //                 textDecoration: 'none',
// // // // //               }}
// // // // //             >
// // // // //               LOGO
// // // // //             </Typography>

// // // // //             <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
// // // // //               <IconButton
// // // // //                 size="large"
// // // // //                 aria-label="account of current user"
// // // // //                 aria-controls="menu-appbar"
// // // // //                 aria-haspopup="true"
// // // // //                 onClick={handleOpenNavMenu}
// // // // //                 color="inherit"
// // // // //               >
// // // // //                 <MenuIcon />
// // // // //               </IconButton>
// // // // //               <Menu
// // // // //                 id="menu-appbar"
// // // // //                 anchorEl={anchorElNav}
// // // // //                 anchorOrigin={{
// // // // //                   vertical: 'bottom',
// // // // //                   horizontal: 'left',
// // // // //                 }}
// // // // //                 keepMounted
// // // // //                 transformOrigin={{
// // // // //                   vertical: 'top',
// // // // //                   horizontal: 'left',
// // // // //                 }}
// // // // //                 open={Boolean(anchorElNav)}
// // // // //                 onClose={handleCloseNavMenu}
// // // // //                 sx={{ display: { xs: 'block', md: 'none' } }}
// // // // //               >
// // // // //                 {pages.map((page) => (
// // // // //                   <MenuItem key={page.name} onClick={handleCloseNavMenu}>
// // // // //                     <NavLink
// // // // //                       to={page.path}
// // // // //                       style={({ isActive }) => ({
// // // // //                         textDecoration: 'none',
// // // // //                         color: isActive ? '#00675B' : 'inherit',
// // // // //                       })}
// // // // //                     >
// // // // //                       {page.name}
// // // // //                     </NavLink>
// // // // //                   </MenuItem>
// // // // //                 ))}
// // // // //               </Menu>
// // // // //             </Box>

// // // // //             <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
// // // // //               {pages.map((page) => (
// // // // //                 <NavLink
// // // // //                   key={page.name}
// // // // //                   to={page.path}
// // // // //                   style={({ isActive }) => ({
// // // // //                     textDecoration: 'none',
// // // // //                     color: isActive ? 'yellow' : 'white',
// // // // //                     margin: '0 10px',
// // // // //                   })}
// // // // //                 >
// // // // //                   <Button sx={{ my: 2, color: 'inherit', display: 'block' }}>{page.name}</Button>
// // // // //                 </NavLink>
// // // // //               ))}
// // // // //             </Box>

// // // // //             <Box sx={{ flexGrow: 0 }}>
// // // // //               <Tooltip title="Open settings">
// // // // //                 <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
// // // // //                   <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
// // // // //                 </IconButton>
// // // // //               </Tooltip>
// // // // //               <Menu
// // // // //                 sx={{ mt: '45px' }}
// // // // //                 id="menu-appbar"
// // // // //                 anchorEl={anchorElUser}
// // // // //                 anchorOrigin={{
// // // // //                   vertical: 'top',
// // // // //                   horizontal: 'right',
// // // // //                 }}
// // // // //                 keepMounted
// // // // //                 transformOrigin={{
// // // // //                   vertical: 'top',
// // // // //                   horizontal: 'right',
// // // // //                 }}
// // // // //                 open={Boolean(anchorElUser)}
// // // // //                 onClose={handleCloseUserMenu}
// // // // //               >
// // // // //                 {settings.map((setting) => (
// // // // //                   <MenuItem key={setting} onClick={handleCloseUserMenu}>
// // // // //                     <Typography textAlign="center">{setting}</Typography>
// // // // //                   </MenuItem>
// // // // //                 ))}
// // // // //               </Menu>
// // // // //             </Box>
// // // // //           </Toolbar>
// // // // //         </Container>
// // // // //       </AppBar>
// // // // //       <Outlet />
// // // // //     </>
// // // // //   );
// // // // // }

// // // // // export default CustomAppBar;


// // // // import React from 'react';
// // // // import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
// // // // import { Link } from 'react-router-dom'; // If using React Router for navigation

// // // // const MyAppBar = () => {
// // // //   return (
// // // //     <AppBar position="sticky">
// // // //       <Toolbar>
// // // //         <Typography variant="h6" sx={{ flexGrow: 1 }}>
// // // //           My App
// // // //         </Typography>
// // // //         <Box>
// // // //           <Button color="inherit" component={Link} to="/">Home</Button>
// // // //           <Button color="inherit" component={Link} to="/about">About</Button>
// // // //           <Button color="inherit" component={Link} to="/contact">Contact</Button>
// // // //         </Box>
// // // //       </Toolbar>
// // // //     </AppBar>
// // // //   );
// // // // };

// // // // export default MyAppBar;


// // // import React, { useState } from "react";
// // // import {
// // //   AppBar,
// // //   Toolbar,
// // //   Typography,
// // //   Button,
// // //   Box,
// // //   IconButton,
// // //   Drawer,
// // //   List,
// // //   ListItem,
// // //   ListItemButton,
// // //   ListItemText,
// // // } from "@mui/material";
// // // import MenuIcon from "@mui/icons-material/Menu";
// // // import { Link, useLocation } from "react-router-dom";

// // // const MyAppBar = () => {
// // //   const [drawerOpen, setDrawerOpen] = useState(false);
// // //   const location = useLocation();

// // //   const toggleDrawer = (open) => {
// // //     setDrawerOpen(open);
// // //   };

// // //   const navItems = [
// // //     { label: "Home", path: "/" },
// // //     { label: "Registration", path: "/registration" },
// // //     { label: "My Gold Stake", path: "/my-gold-stake" },
// // //     { label: "Referrals", path: "/referrals" },
// // //     { label: "About Us", path: "/about-us" },

// // //   ];

// // //   return (
// // //     <>
// // //       <AppBar position="sticky">
// // //         <Toolbar
// // //           sx={{
// // //             display: "flex",
// // //             justifyContent: "center",
// // //           }}
// // //         >
// // //           <Typography variant="h6" sx={{ flexGrow: 1}}>
// // //             My App
// // //           </Typography>
// // //           {/* Mobile Menu Icon */}
// // //           <IconButton
// // //             edge="end"
// // //             color="inherit"
// // //             onClick={() => toggleDrawer(true)}
// // //             sx={{
// // //               display: { xs: "block", md: "none" },
// // //               position: "absolute",
// // //               right: 16,
// // //             }}
// // //           >
// // //             <MenuIcon />
// // //           </IconButton>
// // //           {/* Desktop Nav Items */}
// // //           <Box
// // //             sx={{
// // //               display: { xs: "none", md: "flex" },
// // //               justifyContent: "center",
// // //               gap: 2,
// // //             }}
// // //           >
// // //             {navItems.map((item) => (
// // //               <Box
// // //                 key={item.path}
// // //                 component={Link}
// // //                 to={item.path}
// // //                 color={location.pathname === item.path ? "primary" : "inherit"}
// // //                 sx={{
// // //                   textTransform: "none",
// // //                   bgcolor:
// // //                     location.pathname === item.path ? "primary.light" : "transparent",
// // //                   "&:hover": { bgcolor: "primary.main", color: "white" },
// // //                 }}
// // //               >
// // //                 {item.label}
// // //               </Box>
// // //             ))}
// // //           </Box>
// // //         </Toolbar>
// // //       </AppBar>
// // //       {/* Mobile Drawer */}
// // //       <Drawer
// // //         anchor="left"
// // //         open={drawerOpen}
// // //         onClose={() => toggleDrawer(false)}
// // //       >
// // //         <List sx={{ width: 250 }}>
// // //           {navItems.map((item) => (
// // //             <ListItem key={item.path} disablePadding>
// // //               <ListItemButton
// // //                 component={Link}
// // //                 to={item.path}
// // //                 selected={location.pathname === item.path}
// // //                 onClick={() => toggleDrawer(false)}
// // //               >
// // //                 <ListItemText
// // //                   primary={item.label}
// // //                   sx={{
// // //                     textAlign: "center",
// // //                   }}
// // //                 />
// // //               </ListItemButton>
// // //             </ListItem>
// // //           ))}
// // //         </List>
// // //       </Drawer>
// // //     </>
// // //   );
// // // };

// // // export default MyAppBar;


// // import React, { useState } from "react";
// // import {
// //   AppBar,
// //   Toolbar,
// //   Typography,
// //   Button,
// //   Box,
// //   IconButton,
// //   Drawer,
// //   List,
// //   ListItem,
// //   ListItemButton,
// //   ListItemText,
// //   Grid,
// // } from "@mui/material";
// // import MenuIcon from "@mui/icons-material/Menu";
// // import { Link, useLocation } from "react-router-dom";

// // const MyAppBar = () => {
// //   const [drawerOpen, setDrawerOpen] = useState(false);
// //   const location = useLocation();

// //   const toggleDrawer = (open) => {
// //     setDrawerOpen(open);
// //   };

// //   const navItems = [
// //     { label: "Home", path: "/" },
// //     { label: "Registration", path: "/registration" },
// //     { label: "My Gold Stake", path: "/my-gold-stake" },
// //     { label: "Referrals", path: "/referrals" },
// //     { label: "About Us", path: "/about-us" },
// //   ];

// //   return (
// //     <>
// //       <AppBar position="sticky">
// //         <Toolbar
// //           sx={{
// //             display: "flex",
// //             justifyContent: "space-around",
// //           }}
// //         >
// //           <Typography variant="h6" sx={{ flexGrow: 1 }}>
// //             My App
// //           </Typography>
// //           {/* Mobile Menu Icon */}
// //           <IconButton
// //             edge="end"
// //             color="inherit"
// //             onClick={() => toggleDrawer(true)}
// //             sx={{
// //               display: { xs: "block", md: "none" },
// //               position: "absolute",
// //               right: 16,
// //             }}
// //           >
// //             <MenuIcon />
// //           </IconButton>
// //           {/* Desktop Nav Items */}
// //           <Box
// //             sx={{
// //               display: { xs: "none", md: "flex" },
// //               justifyContent: "center",
// //               gap: 2,
// //             }}
// //           >
// //             {navItems.map((item) => (
// //               <Box
// //                 key={item.path}
// //                 component={Link}
// //                 to={item.path}
// //                 color={location.pathname === item.path ? "primary" : "inherit"}
// //                 sx={{
// //                   textTransform: "none",
// //                   textDecoration: "none",
// //                   bgcolor:
// //                     location.pathname === item.path
// //                       ? "primary.light"
// //                       : "transparent",
// //                   "&:hover": { bgcolor: "primary.main", color: "white" },
// //                   padding: "8px 16px",
// //                   borderRadius: 1,
// //                 }}
// //               >
// //                 {item.label}
// //               </Box>
// //             ))}

// //           </Box>
// //           <Grid></Grid>
// //         </Toolbar>
// //       </AppBar>
// //       {/* Mobile Drawer */}
// //       <Drawer
// //         anchor="left"
// //         open={drawerOpen}
// //         onClose={() => toggleDrawer(false)}
// //       >
// //         <List sx={{ width: 250 }}>
// //           {navItems.map((item) => (
// //             <ListItem key={item.path} disablePadding>
// //               <ListItemButton
// //                 component={Link}
// //                 to={item.path}
// //                 selected={location.pathname === item.path}
// //                 onClick={() => toggleDrawer(false)}
// //                 sx={{
// //                   textDecoration: "none",
// //                   textAlign: "center",
// //                   justifyContent: "center",
// //                   "&.Mui-selected": {
// //                     bgcolor: "primary.main",
// //                     color: "white",
// //                   },
// //                   "&:hover": {
// //                     bgcolor: "primary.light",
// //                   },
// //                 }}
// //               >
// //                 <ListItemText
// //                   primary={item.label}
// //                   sx={{
// //                     textAlign: "center",
// //                   }}
// //                 />
// //               </ListItemButton>
// //             </ListItem>
// //           ))}
// //         </List>
// //       </Drawer>
// //     </>
// //   );
// // };

// // export default MyAppBar;


// // import React, { useState } from "react";
// // import {
// //   AppBar,
// //   Toolbar,
// //   Typography,
// //   Button,
// //   IconButton,
// //   Drawer,
// //   List,
// //   ListItem,
// //   ListItemButton,
// //   ListItemText,
// //   Grid,
// //   Box,
// // } from "@mui/material";
// // import MenuIcon from "@mui/icons-material/Menu";
// // import { Link, useLocation } from "react-router-dom";
// // import logo_image from "../../Assets/Logo_gold.png"
// // import logo_text from "../../Assets/image 6.png"
// // import "./CustomAppBar.css"


// // const MyAppBar = () => {
// //   const [drawerOpen, setDrawerOpen] = useState(false);
// //   const location = useLocation();

// //   const toggleDrawer = (open) => {
// //     setDrawerOpen(open);
// //   };

// //   const navItems = [
// //     { label: "Home", path: "/" },
// //     { label: "Registration", path: "/registration" },
// //     { label: "My Gold Stake", path: "/my-gold-stake" },
// //     { label: "Referrals", path: "/referrals" },
// //     { label: "About Us", path: "/about-us" },
// //   ];

// //   return (
// //     <>
// //       <AppBar position="sticky" sx={{ boxShadow:"none"}} >
// //         <Toolbar
// //           sx={{
// //             display: "flex",
// //             justifyContent: "space-between",
// //             paddingTop: "10px",
// //             paddingBottom: "10px",
// //             background: "#00675B",
// //             borderBottom:"1px solid #989898",



// //           }}
// //         >
// //           <Grid xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
// //             <Box>
// //               <img src={logo_image} style={{ height: "70px", width: "70px", paddingRight: "10px" }}></img>
// //             </Box>
// //             <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", textAlign: "center" }}>
// //               <img src={logo_text} style={{ height: "70px", width: "150px" }}></img>
// //             </Box>
// //             {/* <Typography variant="h6"
// //               sx={{ flexGrow: 1, width: "100%" }}
// //             >
// //               Gold Stake
// //             </Typography> */}
// //           </Grid>
// //           {/* Mobile Menu Icon */}
// //           <IconButton
// //             edge="end"
// //             color="inherit"
// //             onClick={() => toggleDrawer(true)}
// //             sx={{
// //               display: { xs: "block", md: "none" },
// //               position: "absolute",
// //               right: 16,
// //             }}
// //           >
// //             <MenuIcon />
// //           </IconButton>
// //           {/* Desktop Nav Items */}
// //           <Grid

// //             xs={6}
// //             sx={{
// //               display: { xs: "none", md: "flex" },
// //               justifyContent: "center",
// //             }}
// //           >
// //             {navItems.map((item) => (
// //               <Grid item key={item.path}>
// //                 <ListItem
// //                   component={Link}
// //                   to={item.path}
// //                   className={`item ${location.pathname === item.path ? "active" : ""}`}
// //                   // color={location.pathname === item.path ? "red" : "yellow"}
// //                   sx={{
// //                     textTransform: "none",
// //                     textDecoration: "none",
// //                     fontSize:"17px",
// //                     color: location.pathname === item.path ? "#ffaa17" : "white",
// //                     // borderBottom:location.pathname === item.path ? "1px solid white" : "",
// //                     // borderBottom: location.pathname === item.path ? "4px solid red" : "none",
// //                     // background: location.pathname === item.path
// //                     //   ? "transparent linear-gradient(146deg, #DA9127 0%, #E5B144 4%, #EFCD5D 8%, #F6E16F 12%, #FAED79 16%, #FBF17D 20%, #E7BD4A 28%, #D99A28 34%, #CB8F29 36%, #B77F29 40%, #AB762A 43%, #A7732A 47%, #AC782E 51%, #B98738 56%, #CEA04A 61%, #ECC362 67%, #F6CE6A 68%, #F3C963 72%, #EBBA50 78%, #DDA232 85%, #D99A28 87%, #F6CE6A 100%) 0% 0% no-repeat padding-box"
// //                     //   : "none",

// //                     // borderWidth:"50px",
// //                     // borderImage: location.pathname === item.path
// //                     //   ? `url(${logo_image}) 30 stretch`
// //                     //   : "none",
// //                     // borderImageSlice: 1, // Ensures correct slicing

// //                     // bgcolor:
// //                     //   location.pathname === item.path
// //                     //     ? "red"
// //                     //     : "transparent",
// //                     // "&:hover": {bgcolor: "primary.main", color: "white" },
// //                     // padding: "8px 16px",
// //                     borderRadius: 1,
// //                   }}
// //                 >
// //                   {item.label}
// //                 </ListItem>
// //               </Grid>
// //             ))}
// //           </Grid>
// //           <Grid xs={4}></Grid>
// //         </Toolbar>
// //       </AppBar >
// //       {/* Mobile Drawer */}
// //       < Drawer
// //         anchor="left"
// //         open={drawerOpen}
// //         onClose={() => toggleDrawer(false)}
// //       >
// //         <List sx={{ width: 250 }}>
// //           {navItems.map((item) => (
// //             <ListItem key={item.path} disablePadding>
// //               <ListItem
// //                 component={Link}
// //                 to={item.path}
// //                 selected={location.pathname === item.path}
// //                 onClick={() => toggleDrawer(false)}
// //                 sx={{
// //                   textDecoration: "none",
// //                   textAlign: "center",
// //                   justifyContent: "center",
// //                   "&.Mui-selected": {
// //                     // bgcolor: "primary.main",
// //                     color: "red",
// //                   },
// //                   // "&:hover": {
// //                   //   bgcolor: "primary.light",
// //                   // },
// //                 }}
// //               >
// //                 <ListItemText
// //                   primary={item.label}
// //                   sx={{
// //                     textAlign: "center",
// //                   }}
// //                 />
// //               </ListItem>
// //             </ListItem>
// //           ))}
// //         </List>
// //       </Drawer >
// //     </>
// //   );
// // };

// // export default MyAppBar;





// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemText,
//   Grid,
//   Box,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link, useLocation } from "react-router-dom";
// import logo_image from "../../Assets/Logo_gold.png"
// import logo_text from "../../Assets/image 6.png"
// import "./CustomAppBar.css"


// const MyAppBar = () => {

//   const location = useLocation();



//   const navItems = [
//     { label: "Home", path: "/" },
//     { label: "Registration", path: "/registration" },
//     { label: "My Gold Stake", path: "/my-gold-stake" },
//     { label: "Referrals", path: "/referrals" },
//     { label: "About Us", path: "/about-us" },
//   ];

//   return (
//     <>
//       <AppBar position="sticky" sx={{ boxShadow:"none"}} >
//         <Toolbar
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             paddingTop: "10px",
//             paddingBottom: "10px",
//             background: "#00675B",
//             borderBottom:"1px solid #989898",



//           }}
//         >


//           <Grid

//             xs={6}
//             sx={{
//               display: { xs: "none", md: "flex" },
//               justifyContent: "center",
//             }}
//           >
//             {navItems.map((item) => (
//               <Grid item key={item.path}>
//                 <ListItem
//                   component={Link}
//                   to={item.path}
//                   className={`item ${location.pathname === item.path ? "active" : ""}`}
//                   // color={location.pathname === item.path ? "red" : "yellow"}
//                   sx={{
//                     textTransform: "none",
//                     textDecoration: "none",
//                     fontSize:"17px",
//                     color: location.pathname === item.path ? "#ffaa17" : "white",
//                     // borderBottom:location.pathname === item.path ? "1px solid white" : "",
//                     // borderBottom: location.pathname === item.path ? "4px solid red" : "none",
//                     // background: location.pathname === item.path
//                     //   ? "transparent linear-gradient(146deg, #DA9127 0%, #E5B144 4%, #EFCD5D 8%, #F6E16F 12%, #FAED79 16%, #FBF17D 20%, #E7BD4A 28%, #D99A28 34%, #CB8F29 36%, #B77F29 40%, #AB762A 43%, #A7732A 47%, #AC782E 51%, #B98738 56%, #CEA04A 61%, #ECC362 67%, #F6CE6A 68%, #F3C963 72%, #EBBA50 78%, #DDA232 85%, #D99A28 87%, #F6CE6A 100%) 0% 0% no-repeat padding-box"
//                     //   : "none",

//                     // borderWidth:"50px",
//                     // borderImage: location.pathname === item.path
//                     //   ? `url(${logo_image}) 30 stretch`
//                     //   : "none",
//                     // borderImageSlice: 1, // Ensures correct slicing

//                     // bgcolor:
//                     //   location.pathname === item.path
//                     //     ? "red"
//                     //     : "transparent",
//                     // "&:hover": {bgcolor: "primary.main", color: "white" },
//                     // padding: "8px 16px",
//                     borderRadius: 1,
//                   }}
//                 >
//                   {item.label}
//                 </ListItem>
//               </Grid>
//             ))}
//           </Grid>

//         </Toolbar>
//       </AppBar >

//     </>
//   );
// };

// export default MyAppBar;
// -----------------------------------------------------------------------------------

// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemText,
//   Grid,
//   Box,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link, useLocation } from "react-router-dom";
// import logo_image from "../../Assets/Logo_gold.png";
// import logo_text from "../../Assets/image 6.png";
// import "./CustomAppBar.css";

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Box,
  InputBase,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./CustomAppBar.css";
import CustomDropdownMui from "../CustomDropDown/CustomDropdown";
import call_image_white from "../../Assets/call_white_color.svg"
import logo_img from "../../Assets/Logo_img.png";
import search_icom from "../../Assets/search_icom.svg";

import profile_icon from "../../Assets/profile_icon.svg";

import cart_icon from "../../Assets/cart_icon.svg";





const MyAppBar = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
  const isCurrencyMenuOpen = Boolean(currencyAnchorEl);

  const navItems = [
    { label: "Home", path: "/" },
    { label: "Stake Now", path: "/stake-now" },
    { label: "Referrals", path: "/referrals" },

    { label: "About Us", path: "/about-us" },
    { label: "Contact Us", path: "/contact-us" },

  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleCurrencyMenuOpen = (event) => {
    setCurrencyAnchorEl(event.currentTarget);
  };

  const handleCurrencyMenuClose = () => {
    setCurrencyAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
  };


  return (
    <>
      <AppBar position="sticky" sx={{ boxShadow: "none" }}>
        <Box
          sx={{
            // width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 20px",
            background: "#C38F51",
            color: "white",
          }}
        >

          <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
            <img src={call_image_white} style={{ marginRight: "5px", height: "15px", width: "15px" }}></img> +1 (123) 1234567
          </Typography>

          <Button
            endIcon={<ExpandMoreIcon />}
            onClick={handleCurrencyMenuOpen}
            sx={{
              color: "white",
              textTransform: "none",
              fontSize: "14px",
            }}
          >
            $ US Dollar
          </Button>
          <Menu
            anchorEl={currencyAnchorEl}
            open={isCurrencyMenuOpen}
            onClose={handleCurrencyMenuClose}
          >
            <MenuItem onClick={handleCurrencyMenuClose}>US Dollar</MenuItem>
            <MenuItem onClick={handleCurrencyMenuClose}>Euro</MenuItem>
            <MenuItem onClick={handleCurrencyMenuClose}>Rupee</MenuItem>
          </Menu>


        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // width: "100%",
            padding: "10px 50px",
            background: "white", border: "1px solid #C38F51"
          }}
        >

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              // background: "#f0f0f0",
              borderRadius: "4px",
              padding: "2px 10px",
              marginRight: "20px",
            }}
          >
            {/* <SearchIcon sx={{ color: "black" }} /> */}
            <Box sx={{
              border: "1px solid black",
              borderRadius: "50%", background: "black",
              // padding:"10px",
              display: "flex",
              justifyContent: "center", alignItems: "center",
              height: "35px",
              width: "35px"
            }}>
              <img src={search_icom} style={{ height: "16px", width: "16px" }}></img>

            </Box>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              sx={{ marginLeft: "10px", fontSize: "14px", borderBottom: "1.5px solid #C38F51" }}
            />
          </Box>
          {/* Logo */}
          <Box>
            <img
              src={logo_img}
              alt="Logo"
              height="40"
            // style={{ verticalAlign: "middle" }}
            />

          </Box>

          {/* Search Bar */}


          {/* Icons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              gap: "15px",
              color: "black",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {/* <AccountCircleIcon /> */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleMenuOpen}
              >
                <Box
                  sx={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    background: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '35px',
                    width: '35px',
                  }}
                >
                  <img
                    src={profile_icon}
                    alt="profile-icon"
                    style={{ height: '16px', width: '16px' }}
                  />
                </Box>
                <Box sx={{ paddingLeft: '10px' }}>
                  <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    My Account
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '10px' }}>
                    Register
                  </Typography>
                </Box>
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: 1 }}
              >
                <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
                <MenuItem onClick={() => handleNavigation('/register')}>Register</MenuItem>
              </Menu>
            </Box>
            {/* <Box sx={{ textAlign: "center" }}> */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

              {/* <ShoppingCartIcon /> */}
              <Box sx={{
                border: "1px solid black",
                borderRadius: "50%", background: "black",
                // padding:"10px",
                display: "flex",
                justifyContent: "center", alignItems: "center",
                height: "35px",
                width: "35px",

              }}>
                <img src={cart_icon} style={{ height: "16px", width: "16px" }}></img>

              </Box>
              <Box>
                <Typography variant="body2" sx={{
                  fontSize: "12px",
                  paddingLeft: "10px"


                }}>
                  My Cart
                </Typography>

              </Box>
            </Box>
          </Box>
        </Box>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            // background: "#00675B",
            background: "white",
            borderBottom: "1px solid #989898",
          }}
        >
          {/* Logo */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo_image} alt="Logo" height="30" />
          </Typography> */}

          {/* Desktop Navigation */}
          <Grid
            container
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {navItems.map((item) => (
              <Grid item key={item.path}>
                <ListItem
                  component={Link}
                  to={item.path}
                  // className={`item ${location.pathname === item.path ? "active" : ""}`}
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    fontSize: "17px", marginRight: "20px",
                    color: location.pathname === item.path ? "#C38F51" : "black",
                    borderRadius: 1,

                    "&:hover": {
                      color: "#C38F51",
                    },
                  }}
                >
                  {item.label}
                </ListItem>
              </Grid>
            ))}
          </Grid>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="start"
            color="black"
            aria-label="menu"
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile Drawer */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {navItems.map((item) => (
                  <ListItem key={item.path} disablePadding>
                    <ListItemButton component={Link} to={item.path}>
                      <ListItemText
                        primary={item.label}
                        sx={{
                          color:
                            location.pathname === item.path ? "#ffaa17" : "black",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar >
    </>
  );
};

export default MyAppBar;
