



import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import ReferEranCards from "./ReferEranCard";

const DashedArrow = styled("div")(() => ({
  position: "absolute",
  borderTop: "2px dashed black",
  width: "calc(33% - 40px)", // Adjust the width for proper spacing
  top: "50%",
  transform: "translateY(-50%)",
  left: "calc(33% - 20px)", // Adjust for spacing between cards
  "&:after": {
    content: '""',
    position: "absolute",
    top: "-6px",
    right: "-12px",
    width: "0",
    height: "0",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "8px solid black",
  },
}));

const Refer_EarnPage = () => {
  const steps = [
    {
      title: "Lorem Ipsum",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
      icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
    },
    {
      title: "Lorem Ipsum",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
      icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
    },
    {
      title: "Lorem Ipsum",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
      icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
    },
  ];

  return (
    <>
      <Box sx={{ p: 4 }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ paddingBottom: "5px", fontSize: "30px" }}
          >
            Refer & Earn in
            <span style={{ color: "black", fontWeight: "800" }}>Simple Steps</span>
          </Typography>
          <Typography
            sx={{
              paddingBottom: "5px",
              maxWidth: "900px",
              margin: "0 auto",
              textAlign: "center",
              color: "#b0aaaa",
              fontSize: "14px",
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum has been the industry’s standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled it to
            make a type specimen book.
          </Typography>
        </Box>

        {/* <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
          <Grid container justifyContent="center" spacing={4} alignItems="center">
            {steps.map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <ProsperityCard
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
                <Box sx={{ border: "1px dashed red" }}> </Box>

              </Grid>

            ))}
          </Grid> */}
        <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid container justifyContent="center" spacing={4} alignItems="center">
            {steps.map((item, index) => (
              <Grid item xs={12} sm={4} key={index} sx={{ position: "relative" }}>
                <ReferEranCards
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
                {/* Curved Arrow */}
                {/* {index < steps.length - 1 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%", // Center vertically
                      right: "-50px", // Adjust based on curve size
                      width: "100px", // Width of the arrow
                      height: "50px", // Height of the curve
                      border: "none",
                      borderTop: "2px dashed #ccc", // Dashed line
                      borderRadius: "50%", // Makes it curved
                      transform: "translateY(-50%)",
                      zIndex: -1,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        right: "-10px", // Position for arrowhead
                        width: "0",
                        height: "0",
                        borderLeft: "6px solid #ccc",
                        borderTop: "6px solid transparent",
                        borderBottom: "6px solid transparent",
                        transform: "rotate(45deg)",
                      }}
                    />
                  </Box>
                )} */}
              </Grid>
            ))}
          </Grid>
        </Box>


        {/* Add the dashed arrows between the cards */}
        {/* {steps.slice(0, -1).map((_, index) => (
            <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
          ))} */}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default Refer_EarnPage;
