

import React from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
// Ensure this is the correct path
import refer_img from "../../../Assets/refer_img.svg"; // Ensure this is the correct path
import { FaShareAlt } from "react-icons/fa";



const InviteFriends = () => {
    return (
        <Box
            sx={{
                position: "relative", // Needed for positioning child elements
                background: "#F7F7F7",

                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "50px 0px",
                // left:"100px"
                // minHeight: "90vh", // Adjust height as needed
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Box
                    display="flex"
                    // alignItems="center"
                    justifyContent="space-around"
                    flexWrap="wrap"
                >
                    {/* Left Section */}
                    <Box
                        sx={{

                            color: "#fff",
                        }}
                    >
                        <Typography
                            variant="h3"
                            textAlign="start"
                            sx={{
                                fontWeight: "bold",
                                fontSize: "38px",
                                maxWidth: "600px",
                                color: "#3B4056",
                                mb: 2
                            }}
                        >
                            Invite your Friends
                        </Typography>

                        <Typography
                            variant="body2"
                            textAlign="start"
                            sx={{
                                mb: 3,
                                lineHeight: "1.6",
                                color: "#7A7A7A",
                                // width:"55%",
                                maxWidth: "500px",
                                fontSize: "13px",

                                letterSpacing: "0px",
                                // color: "#b0aaaa",
                                opacity: 1
                            }}
                        >
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                            industry’s standard dummy text ever since the 1500s, when an unknown
                        </Typography>
                        <Stack spacing={2} direction="row">
                         
                            <Button
                                variant="outlined"

                                sx={{
                                    // backgroundColor: "#C38F51",
                                    textTransform: "none",
                                    color: "#7A7A7A",
                                    fontSize: "12px",
                                    padding: "0px 50px",
                                    height: "35px",
                                    borderColor:"white",
                                    borderRadius: "23px",
                                    background: "white 0% 0% no-repeat padding-box",
                                    "&:hover": {
                                        backgroundColor: "white",
                                    },
                                }}
                            >
                                www.goldstack?ref=i3yvn5

                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<FaShareAlt style={{color:"white",fontSize:"14px"}}/>}

                                sx={{
                                    // backgroundColor: "#C38F51",
                                    textTransform: "none",
                                    color: "white",
                                    fontSize: "14px",
                                    padding: "0px 30px",
                                    height: "35px",
                                    borderRadius: "23px",
                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                    "&:hover": {
                                        backgroundColor: "#C38F51",
                                    },
                                }}
                            >
                                Share Referral Code
                            </Button>

                        </Stack>
                    </Box>
                    <Box>
                        <img src={refer_img} style={{ height: "220px" }}></img>
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default InviteFriends;
