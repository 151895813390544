// import logo from './logo.svg';
// import './App.css';
// import CustomAppBar from './Components/AppBar/CustomAppBar';
// import Home from './Pages/Home/Home';
// import ProsperitySteps from './Pages/ProperitySteps/ProperitySteps';
// import OfferSection from './Pages/OfferSection/OfferSection';


// function App() {
//   return (
//     <div className="App">

//       <CustomAppBar />
//       <Home />
//       <ProsperitySteps />
//       <OfferSection />
//     </div>
//   );
// }

// export default App;



// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// import './App.css';
// import CustomAppBar from './Components/AppBar/CustomAppBar';
// import Home from './Pages/Home/Home';
// import ProsperitySteps from './Pages/ProperitySteps/ProperitySteps';
// import OfferSection from './Pages/OfferSection/OfferSection';

// function App() {
//   return (
//     <div className="App">
//       <div 
//       // style={{ position: "relative" }}
//       >
//         <CustomAppBar />
//       </div>
//       <div 
//       // style={{ position: "absolute",top:50 }}
//       >


//         <Routes>
//           <Route path="/" element={<Home />} />

//         </Routes>
//       </div>
//     </div>
//   );
// }

// export default App;


// 

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyAppBar from './Components/AppBar/CustomAppBar'; // The AppBar component you created
import Home from './Pages/Home/Home'; // The AppBar component you created
import Footer from './Pages/Footer/Footer';
import LoginComponent from './Components/LoginComponent/LoginComponent';
import RegistrationComponent from './Components/RegistrationComponent/RegistrationComponent';
import StakeNowHome from './Pages/StakeNow/Home/StakeNowHome';
import ReferralHome from './Pages/Referrals/ReferralHome/ReferralHome';
import AddToCartHome from './Pages/StakeNow/AddToCart/AddToCartHome';

// const Home = () => <div>Home Page</div>;
// const About = () => <div>About Page</div>;
// const Contact = () => <div>Contact Page</div>;

const App = () => {
  return (
    // <Router>
    <div >
      <div style={{ minHeight: "70vh" }}>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stake-now" element={<StakeNowHome />} />
          
          <Route path="/referrals" element={<ReferralHome/>} />

          <Route path="/login" element={<LoginComponent />} />
          <Route path="/register" element={<RegistrationComponent />} />
          <Route path="/Add-to-cart" element={<AddToCartHome />} />

          
          
          
          {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />  */}
        </Routes>
      </div>

    </div>
  );
};

export default App;
