

// import React, { useState } from 'react';
// import { Box, Button, Grid, IconButton, TextField, Typography, Link } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { Formik, Field, Form } from 'formik';
// import * as Yup from 'yup';  // Optional: For validation
// import login_bgImage from "../../Assets/login_bgImage.png";
// import CustomInput from '../CustomInput/CustomInput';
// import CustomInputLogin from '../CustomInputLogin/CustomInputLogin';
// import logo_login_image from "../../Assets/logo_login_image.png";
// import {


//     MenuItem,
//     InputAdornment,

//     Stepper,
//     Step,
//     StepLabel,
// } from '@mui/material';
// import { Send, CheckCircle } from '@mui/icons-material';


// const RegistrationComponent = () => {
//     const [showPassword, setShowPassword] = useState(false);

//     // Toggle password visibility
//     const togglePasswordVisibility = () => {
//         setShowPassword((prev) => !prev);
//     };

//     const initialValues = {
//         emailid: '',
//         password: ''
//     };

//     // Optional: validation schema using Yup
//     const validationSchema = Yup.object({
//         emailid: Yup.string().required('Email Id is reuired'),
//         password: Yup.string().required('Password is required')
//     });

//     const handleSubmit = (values) => {
//         // Add form submission logic
//         console.log(values, "values");  // You can log the form values or handle API calls here
//     };


//     const [mobileVerified, setMobileVerified] = useState(false);

//     const steps = ['Basic Information', 'Address', 'Documents'];

//     return (
//         <Grid container sx={{ boxShadow: 3, height: '100vh' }}>
//             <Grid
//                 item
//                 md={5}
//                 sx={{
//                     display: { xs: 'none', md: 'flex' },
//                     backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 11%, rgba(84, 84, 84, 0) 100%), url(${login_bgImage})`,
//                     backgroundSize: 'cover',
//                     backgroundRepeat: 'no-repeat',
//                     backgroundPosition: 'center',
//                     boxShadow: 5,
//                     width: '100%',
//                     height: '100vh',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     flexDirection: 'column', // Ensures content stacks vertically
//                     // mb:2
//                 }}
//             >
//                 <img
//                     src={logo_login_image}
//                     alt="logo"
//                     width="213px"
//                     height="61px"
//                 />
//                 <Box
//                     display="flex"
//                     // alignItems="center"
//                     justifyContent="center"
//                     flexWrap="wrap"
//                     mt={2}
//                 >
//                     {/* Left Section */}
//                     <Box
//                         sx={{

//                             color: "#fff",
//                         }}
//                     >
//                         <Typography
//                             variant="h3"
//                             textAlign="center"
//                             sx={{
//                                 fontWeight: "bold",
//                                 fontSize: "50px",
//                                 mb: "1px"
//                             }}
//                         >
//                             The Best Gold
//                         </Typography>
//                         <Typography
//                             variant="h3"
//                             textAlign="center"
//                             sx={{
//                                 fontWeight: "bold",
//                                 fontSize: "35px",
//                                 mb: 2,
//                             }}
//                         >
//                             Staking Company
//                         </Typography>
//                         <Typography
//                             variant="body2"
//                             textAlign="center"
//                             sx={{
//                                 // mb: 5,
//                                 lineHeight: "1.6",
//                                 color: "#ffffff",
//                                 // width:"55%",
//                                 maxWidth: "500px",

//                                 letterSpacing: "0px",
//                                 color: "#b0aaaa",
//                                 opacity: 1
//                             }}
//                         >
//                             We are committed to providing our customers with exceptional
//                             service while offering our employees the best training service while offering our employees.
//                             We are committed to providing our customers with exceptional
//                             service while offering our employees
//                         </Typography>
//                     </Box>
//                 </Box>
//             </Grid>
//             <Grid
//                 item
//                 xs={12}
//                 md={7}
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     p: 4,
//                     background: '#FFFFFF',
//                     width: '100%',
//                 }}
//             >
//                 <Box
//                     sx={{
//                         width: '100%',
//                         // maxWidth: 350, // Added for consistent width
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center', // Aligns all children centrally
//                         justifyContent: 'center',
//                     }}
//                 >
//                     <Typography
//                         variant="h4"
//                         textAlign="center"
//                         sx={{ fontSize: "30px", fontWeight: "600", mb: 4 }}
//                     >
//                         Registration
//                     </Typography>

//                     <Stepper activeStep={0} alternativeLabel sx={{width:"100%",padding:"none"}}>
//                         {steps.map((label) => (
//                             <Step key={label}>
//                                 <StepLabel>{label}</StepLabel>
//                             </Step>
//                         ))}
//                     </Stepper>

//                     <Box component="form" mt={3} noValidate>
//                         <Box display="flex" gap={2}>
//                             <TextField
//                                 label="First Name"
//                                 variant="outlined"
//                                 fullWidth
//                                 required
//                             />
//                             <TextField
//                                 label="Last Name"
//                                 variant="outlined"
//                                 fullWidth
//                                 required
//                             />
//                         </Box>

//                         <Box display="flex" gap={2} mt={2}>
//                             <TextField
//                                 label="Type"
//                                 variant="outlined"
//                                 fullWidth
//                                 select
//                                 required
//                             >
//                                 <MenuItem value="Individual">Individual</MenuItem>
//                                 <MenuItem value="Company">Company</MenuItem>
//                             </TextField>
//                             <TextField
//                                 label="Referral Code (if Any)"
//                                 variant="outlined"
//                                 fullWidth
//                             />
//                         </Box>

//                         <Box display="flex" gap={2} mt={2}>
//                             <TextField
//                                 label="Email ID"
//                                 variant="outlined"
//                                 fullWidth
//                                 required
//                                 InputProps={{
//                                     endAdornment: (
//                                         <InputAdornment position="end">
//                                             <Button variant="text" size="small">
//                                                 Send OTP
//                                             </Button>
//                                         </InputAdornment>
//                                     ),
//                                 }}
//                             />
//                             <TextField
//                                 label="Mobile Number"
//                                 variant="outlined"
//                                 fullWidth
//                                 required
//                                 InputProps={{
//                                     startAdornment: (
//                                         <InputAdornment position="start">+971</InputAdornment>
//                                     ),
//                                     endAdornment: (
//                                         <InputAdornment position="end">
//                                             {mobileVerified ? (
//                                                 <CheckCircle color="success" />
//                                             ) : (
//                                                 <IconButton onClick={() => setMobileVerified(true)}>
//                                                     <Send />
//                                                 </IconButton>
//                                             )}
//                                         </InputAdornment>
//                                     ),
//                                 }}
//                             />
//                         </Box>

//                         <Box display="flex" gap={2} mt={2}>
//                             <TextField
//                                 label="Password"
//                                 variant="outlined"
//                                 fullWidth
//                                 required
//                                 type="password"
//                             />
//                             <TextField
//                                 label="Confirm Password"
//                                 variant="outlined"
//                                 fullWidth
//                                 required
//                                 type="password"
//                             />
//                         </Box>

//                         <Box mt={3} textAlign="right">
//                             <Button variant="contained" color="primary">
//                                 Next
//                             </Button>
//                         </Box>
//                     </Box>

//                     {/* <Typography
//                         variant="body2"
//                         mt={2}
//                         sx={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             textAlign: 'center', // Ensure centered text
//                         }}
//                     >
//                         Don't have an account?&nbsp;
//                         <Typography sx={{ color: "#C38F51" }} underline="none">Register here</Typography>
//                     </Typography> */}
//                 </Box>
//             </Grid>

//         </Grid >
//     );
// };

// export default RegistrationComponent;




import React, { useState } from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    MenuItem,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
} from '@mui/material';
import { Send, CheckCircle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import login_bgImage from "../../Assets/login_bgImage.png";
import logo_login_image from "../../Assets/logo_login_image.png";

// Styled Stepper with dashed lines
const DashedStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepConnector-line': {
        borderStyle: 'dashed',
    },
    '& .MuiStepConnector-root': {
        minHeight: 2,
    },
}));

const RegistrationComponent = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [mobileVerified, setMobileVerified] = useState(false);

    const steps = ['Basic Information', 'Address', 'Documents'];

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    return (
        <Grid container sx={{ boxShadow: 3, height: '100vh' }}>
            <Grid
                item
                md={5}
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 11%, rgba(84, 84, 84, 0) 100%), url(${login_bgImage})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    boxShadow: 5,
                    width: '100%',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <img
                    src={logo_login_image}
                    alt="logo"
                    width="213px"
                    height="61px"
                />
                <Box display="flex" justifyContent="center" flexWrap="wrap" mt={2}>
                    <Box sx={{ color: "#fff" }}>
                        <Typography
                            variant="h3"
                            textAlign="center"
                            sx={{ fontWeight: "bold", fontSize: "50px", mb: "1px" }}
                        >
                            The Best Gold
                        </Typography>
                        <Typography
                            variant="h3"
                            textAlign="center"
                            sx={{ fontWeight: "bold", fontSize: "35px", mb: 2 }}
                        >
                            Staking Company
                        </Typography>
                        <Typography
                            variant="body2"
                            textAlign="center"
                            sx={{
                                lineHeight: "1.6",
                                color: "#b0aaaa",
                                maxWidth: "500px",
                                letterSpacing: "0px",
                                opacity: 1,
                            }}
                        >
                            We are committed to providing our customers with exceptional
                            service while offering our employees the best training service while offering our employees.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={7}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 4,
                    background: '#FFFFFF',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="h4"
                        textAlign="center"
                        sx={{ fontSize: "30px", fontWeight: "600", mb: 4 }}
                    >
                        Registration
                    </Typography>

                    {/* Stepper */}
                    <DashedStepper activeStep={activeStep} alternativeLabel sx={{ width: "100%" }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </DashedStepper>

                    {/* Form */}
                    <Box component="form" mt={3} noValidate>
                        <Box display="flex" gap={2}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Box>

                        <Box display="flex" gap={2} mt={2}>
                            <TextField
                                label="Type"
                                variant="outlined"
                                fullWidth
                                select
                                required
                            >
                                <MenuItem value="Individual">Individual</MenuItem>
                                <MenuItem value="Company">Company</MenuItem>
                            </TextField>
                            <TextField
                                label="Referral Code (if Any)"
                                variant="outlined"
                                fullWidth
                            />
                        </Box>

                        <Box display="flex" gap={2} mt={2}>
                            <TextField
                                label="Email ID"
                                variant="outlined"
                                fullWidth
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant="text" size="small">
                                                Send OTP
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">+971</InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {mobileVerified ? (
                                                <CheckCircle color="success" />
                                            ) : (
                                                <Button onClick={() => setMobileVerified(true)}>
                                                    <Send />
                                                </Button>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box display="flex" gap={2} mt={2}>
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                required
                                type="password"
                            />
                            <TextField
                                label="Confirm Password"
                                variant="outlined"
                                fullWidth
                                required
                                type="password"
                            />
                        </Box>

                        <Box mt={3} textAlign="right">
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default RegistrationComponent;
