// import React from 'react';
// import {
//     Accordion,
//     AccordionSummary,
//     AccordionDetails,
//     Typography,
//     Grid,
//     Box,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// const GoldInfoAccordion = () => {
//     const sections = [
//         {
//             title: 'Where This Gold Is Staked',
//             content:
//                 'The Gold purchased by you is in safe hands and will be available in the Gold Bullion exchange located in Dubai',
//         },
//         {
//             title: 'How Is The Promised Returns Viable',
//             content: 'We invest on Bullion and already staked 10 TONNES of GOLD',
//         },
//         {
//             title: 'Proof Of Investment',
//             content:
//                 'The Gold is purchased on the date of online purchase approval, and a certificate will be staked in <MY GOLD STAKE> section of this website',
//         },
//         {
//             title: 'Lock-in Terms',
//             content:
//                 'Since this gold is in the reserve and we wanted to provide a clear',
//         },
//         {
//             title: 'How To Redeem',
//             content: 'The Redemption Process',
//         },
//         {
//             title: 'Assuring Security & Safety',
//             content:
//                 'Since this gold is in the reserve and we wanted to provide a clear',
//         },
//         {
//             title: 'Data Security',
//             content:
//                 'Since this gold is in the reserve and we wanted to provide a clear',
//         },
//     ];

//     return (
//         <div style={{padding:5}}>
//             <Grid container spacing={4}>
//                 {sections.map((section, index) => (
//                     <Grid>
//                         <Box>
//                             <Typography>
//                                 {section.title}
//                             </Typography>
//                             <Typography>
//                                 {section.content}
//                             </Typography>
//                         </Box>
//                     </Grid>
//                 ))}
//             </Grid>
//         </div>
//     );
// };

// export default GoldInfoAccordion;



import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const GoldInfoAccordion = () => {
    const sections = [
        {
            title: 'Where This Gold Is Staked',
            content:
                'The Gold purchased by you is in safe hands and will be available in the Gold Bullion exchange located in Dubai',
        },
        {
            title: 'How Is The Promised Returns Viable',
            content: 'We invest on Bullion and already staked 10 TONNES of GOLD',
        },
        {
            title: 'Proof Of Investment',
            content:
                'The Gold is purchased on the date of online purchase approval, and a certificate will be staked in <MY GOLD STAKE> section of this website',
        },
        {
            title: 'Lock-in Terms',
            content:
                'Since this gold is in the reserve and we wanted to provide a clear',
        },
        {
            title: 'How To Redeem',
            content: 'The Redemption Process',
        },
        {
            title: 'Assuring Security & Safety',
            content:
                'Since this gold is in the reserve and we wanted to provide a clear',
        },
        {
            title: 'Data Security',
            content:
                'Since this gold is in the reserve and we wanted to provide a clear',
        },
    ];

    return (
        <Box sx={{py:4}}>
            <Grid container spacing={2}>
                {sections.map((section, index) => (
                    <Grid item xs={12} key={index}>
                        <Box
                            sx={{
                                padding: 2,
                                backgroundColor: '#F7F7F7',
                                borderRadius: 2,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                // border: '1px solid #ddd',
                                '&:hover': {
                                    // boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
                                    // borderColor: '#aaa',
                                },
                                transition: 'all 0.3s ease-in-out',
                            }}
                        >
                            <Typography
                                
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: '600',
                                    fontSize:"16px",
                                    color: '#C38F51',
                                    // textAlign: 'center',
                                }}
                            >
                                {section.title}
                            </Typography>
                            <Typography
                                // variant="body2"
                                sx={{ color: '#555', 
                                    // textAlign: 'justify'
                                    fontSize:"14px"
                                 }}
                            >
                                {section.content}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default GoldInfoAccordion;
