


import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import Pusrchase_GoldStakeCard from "../../../Components/Pusrchase_GoldStakeCard/Pusrchase_GoldStakeCard";
import SchemeCard from "../../../Components/SchemeCard/SchemeCard";
import cardImage from "../../../Assets/home_page_Image.png"


const PurchaseSchemes = () => {
    const schemes = [
        {
            name: "Scheme Name 1",
            valueOfPurchase: "$1000",
            purchase: "13.8 Grm",
            maturity: "2 Years",
            lockIn: "24 Month's",
            benefit: "6%*r",
            closingDate: "15/Mar/2025",
            backgroundImage: cardImage, // replace with the background image URL
        },
        {
            name: "Scheme Name 2",
            valueOfPurchase: "$10000",
            purchase: "138 Grm",
            maturity: "2 Years",
            lockIn: "24 Month's",
            benefit: "6%*",
            closingDate: "15/Mar/2025",
            backgroundImage: cardImage, // replace with the background image URL
        },
    ];

    return (
        <>
            <Box sx={{ p: 4 }}>
                <Box sx={{ textAlign: "center", mb: 4 }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ paddingBottom: "5px", fontSize: "30px" }}
                    >
                        Purchase &
                        <span style={{ color: "black", fontWeight: "800" }}>Schemes</span>
                    </Typography>
                    <Typography
                        sx={{
                            paddingBottom: "5px",
                            maxWidth: "900px",
                            margin: "0 auto",
                            textAlign: "center",
                            color: "#b0aaaa",
                            fontSize: "14px",
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book.
                    </Typography>
                </Box>


                <Box 
                // sx={{
                //       position: "relative",
                //       display: "flex", justifyContent: "space-between", alignItems: "center" }}
                      >
                    {/* <Grid
                      container justifyContent="center" spacing={6} alignItems="center"
                     > */}
                        {/* {schemes.map((schemes, index) => ( */}
                            <SchemeCard schemes={schemes}/>
                        {/* ))} */}
                    {/* </Grid> */}
                </Box>



            </Box>
        </>
    );
};

export default PurchaseSchemes;
