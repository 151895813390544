

import React from 'react';
// import LandingPage from './LandingPage';
// import ProsperitySteps from '../ProperitySteps/ProperitySteps';
// import OfferSection from '../OfferSection/OfferSection';
// import FAQSection from '../FAQSection/FAQSection';
// import Footer from '../Footer/Footer';
// import ReferSection from '../ReferSection/ReferSection';
// import ContactUsection from '../ContactUsection/ContactUsection';
import { IconButton, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import MyAppBar from '../../Components/AppBar/CustomAppBar';
import Footer from '../../Footer/Footer';
import StakeNowMainPage from '../StakeNowMainPage/StakeNowMainPage';
import MyAppBar from '../../../Components/AppBar/CustomAppBar';
import Pusrchase_GoldStake from '../Purchase&GoldStack/Pusrchase_GoldStake';
import ReferSection from '../../ReferSection/ReferSection';
import PurchaseSchemes from '../Purchase&Schemes/PurchaseSchemes';


const StakeNowHome = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (

    <div style={{ position: "relative" }}>
      <MyAppBar />

      <StakeNowMainPage />
      <Pusrchase_GoldStake />
      <ReferSection />
      <PurchaseSchemes />

      <Footer />

      {/* <Fab
        size="small"
        aria-label="scroll to top"
        onClick={handleScrollToTop}
        sx={{
          position: "sticky",
          bottom: 0,
          right: 0,
          // top: -30,
          borderRadius: "10px",
          boxShadow: "none",
          transform: "translateX(50%)",
          backgroundColor: "red",
          color: "#C38F51",
          "&:hover": {
            backgroundColor: "black",
            color: "white"
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab> */}

    </div>
  )
}


export default StakeNowHome;



